import { Outlet } from 'react-router-dom';

import { WorkspaceProvider } from '@/app/workspace/context/WorkspaceContext';
import { WorkspaceDashboard } from '@/components/page-templates/WorkspaceDashboard';

export const WorkspacePage = () => {
  return (
    <WorkspaceProvider>
      <WorkspaceDashboard>
        <Outlet />
      </WorkspaceDashboard>
    </WorkspaceProvider>
  );
};

import { JurisdictionEnum } from './enums';

export const JURISDICTION_NAMES: Record<JurisdictionEnum, string> = {
  // Africa
  [JurisdictionEnum.DZ]: 'Algeria',
  [JurisdictionEnum.AO]: 'Angola',
  [JurisdictionEnum.BJ]: 'Benin',
  [JurisdictionEnum.BW]: 'Botswana',
  [JurisdictionEnum.BF]: 'Burkina Faso',
  [JurisdictionEnum.BI]: 'Burundi',
  [JurisdictionEnum.CM]: 'Cameroon',
  [JurisdictionEnum.CV]: 'Cape Verde',
  [JurisdictionEnum.CF]: 'Central African Republic',
  [JurisdictionEnum.TD]: 'Chad',
  [JurisdictionEnum.KM]: 'Comoros',
  [JurisdictionEnum.CG]: 'Congo',
  [JurisdictionEnum.CD]: 'Democratic Republic of the Congo',
  [JurisdictionEnum.CI]: "Côte d'Ivoire",
  [JurisdictionEnum.DJ]: 'Djibouti',
  [JurisdictionEnum.EG]: 'Egypt',
  [JurisdictionEnum.GQ]: 'Equatorial Guinea',
  [JurisdictionEnum.ER]: 'Eritrea',
  [JurisdictionEnum.ET]: 'Ethiopia',
  [JurisdictionEnum.GA]: 'Gabon',
  [JurisdictionEnum.GM]: 'Gambia',
  [JurisdictionEnum.GH]: 'Ghana',
  [JurisdictionEnum.GN]: 'Guinea',
  [JurisdictionEnum.GW]: 'Guinea-Bissau',
  [JurisdictionEnum.KE]: 'Kenya',
  [JurisdictionEnum.LS]: 'Lesotho',
  [JurisdictionEnum.LR]: 'Liberia',
  [JurisdictionEnum.LY]: 'Libya',
  [JurisdictionEnum.MG]: 'Madagascar',
  [JurisdictionEnum.MW]: 'Malawi',
  [JurisdictionEnum.ML]: 'Mali',
  [JurisdictionEnum.MR]: 'Mauritania',
  [JurisdictionEnum.MU]: 'Mauritius',
  [JurisdictionEnum.MA]: 'Morocco',
  [JurisdictionEnum.MZ]: 'Mozambique',
  [JurisdictionEnum.NA]: 'Namibia',
  [JurisdictionEnum.NE]: 'Niger',
  [JurisdictionEnum.NG]: 'Nigeria',
  [JurisdictionEnum.RW]: 'Rwanda',
  [JurisdictionEnum.ST]: 'São Tomé and Príncipe',
  [JurisdictionEnum.SN]: 'Senegal',
  [JurisdictionEnum.SC]: 'Seychelles',
  [JurisdictionEnum.SL]: 'Sierra Leone',
  [JurisdictionEnum.SO]: 'Somalia',
  [JurisdictionEnum.ZA]: 'South Africa',
  [JurisdictionEnum.SS]: 'South Sudan',
  [JurisdictionEnum.SD]: 'Sudan',
  [JurisdictionEnum.SZ]: 'Eswatini',
  [JurisdictionEnum.TZ]: 'Tanzania',
  [JurisdictionEnum.TG]: 'Togo',
  [JurisdictionEnum.TN]: 'Tunisia',
  [JurisdictionEnum.UG]: 'Uganda',
  [JurisdictionEnum.ZM]: 'Zambia',
  [JurisdictionEnum.ZW]: 'Zimbabwe',

  // Asia
  [JurisdictionEnum.AF]: 'Afghanistan',
  [JurisdictionEnum.AM]: 'Armenia',
  [JurisdictionEnum.AZ]: 'Azerbaijan',
  [JurisdictionEnum.BH]: 'Bahrain',
  [JurisdictionEnum.BD]: 'Bangladesh',
  [JurisdictionEnum.BT]: 'Bhutan',
  [JurisdictionEnum.BN]: 'Brunei',
  [JurisdictionEnum.KH]: 'Cambodia',
  [JurisdictionEnum.CN]: 'China',
  [JurisdictionEnum.CY]: 'Cyprus',
  [JurisdictionEnum.GE]: 'Georgia',
  [JurisdictionEnum.IN]: 'India',
  [JurisdictionEnum.ID]: 'Indonesia',
  [JurisdictionEnum.IR]: 'Iran',
  [JurisdictionEnum.IQ]: 'Iraq',
  [JurisdictionEnum.IL]: 'Israel',
  [JurisdictionEnum.JP]: 'Japan',
  [JurisdictionEnum.JO]: 'Jordan',
  [JurisdictionEnum.KZ]: 'Kazakhstan',
  [JurisdictionEnum.KW]: 'Kuwait',
  [JurisdictionEnum.KG]: 'Kyrgyzstan',
  [JurisdictionEnum.LA]: 'Laos',
  [JurisdictionEnum.LB]: 'Lebanon',
  [JurisdictionEnum.MY]: 'Malaysia',
  [JurisdictionEnum.MV]: 'Maldives',
  [JurisdictionEnum.MN]: 'Mongolia',
  [JurisdictionEnum.MM]: 'Myanmar',
  [JurisdictionEnum.NP]: 'Nepal',
  [JurisdictionEnum.OM]: 'Oman',
  [JurisdictionEnum.PK]: 'Pakistan',
  [JurisdictionEnum.PH]: 'Philippines',
  [JurisdictionEnum.QA]: 'Qatar',
  [JurisdictionEnum.SA]: 'Saudi Arabia',
  [JurisdictionEnum.SG]: 'Singapore',
  [JurisdictionEnum.KR]: 'South Korea',
  [JurisdictionEnum.LK]: 'Sri Lanka',
  [JurisdictionEnum.SY]: 'Syria',
  [JurisdictionEnum.TW]: 'Taiwan',
  [JurisdictionEnum.TJ]: 'Tajikistan',
  [JurisdictionEnum.TH]: 'Thailand',
  [JurisdictionEnum.TL]: 'Timor-Leste',
  [JurisdictionEnum.TR]: 'Türkiye',
  [JurisdictionEnum.TM]: 'Turkmenistan',
  [JurisdictionEnum.AE]: 'United Arab Emirates',
  [JurisdictionEnum.UZ]: 'Uzbekistan',
  [JurisdictionEnum.VN]: 'Vietnam',
  [JurisdictionEnum.YE]: 'Yemen',

  // Europe
  [JurisdictionEnum.AL]: 'Albania',
  [JurisdictionEnum.AD]: 'Andorra',
  [JurisdictionEnum.AT]: 'Austria',
  [JurisdictionEnum.BY]: 'Belarus',
  [JurisdictionEnum.BE]: 'Belgium',
  [JurisdictionEnum.BA]: 'Bosnia and Herzegovina',
  [JurisdictionEnum.BG]: 'Bulgaria',
  [JurisdictionEnum.HR]: 'Croatia',
  [JurisdictionEnum.CZ]: 'Czech Republic',
  [JurisdictionEnum.DK]: 'Denmark',
  [JurisdictionEnum.EE]: 'Estonia',
  [JurisdictionEnum.FI]: 'Finland',
  [JurisdictionEnum.FR]: 'France',
  [JurisdictionEnum.DE]: 'Germany',
  [JurisdictionEnum.GR]: 'Greece',
  [JurisdictionEnum.HU]: 'Hungary',
  [JurisdictionEnum.IS]: 'Iceland',
  [JurisdictionEnum.IE]: 'Ireland',
  [JurisdictionEnum.IT]: 'Italy',
  [JurisdictionEnum.XK]: 'Kosovo',
  [JurisdictionEnum.LV]: 'Latvia',
  [JurisdictionEnum.LI]: 'Liechtenstein',
  [JurisdictionEnum.LT]: 'Lithuania',
  [JurisdictionEnum.LU]: 'Luxembourg',
  [JurisdictionEnum.MT]: 'Malta',
  [JurisdictionEnum.MD]: 'Moldova',
  [JurisdictionEnum.MC]: 'Monaco',
  [JurisdictionEnum.ME]: 'Montenegro',
  [JurisdictionEnum.NL]: 'Netherlands',
  [JurisdictionEnum.MK]: 'North Macedonia',
  [JurisdictionEnum.NO]: 'Norway',
  [JurisdictionEnum.PL]: 'Poland',
  [JurisdictionEnum.PT]: 'Portugal',
  [JurisdictionEnum.RO]: 'Romania',
  [JurisdictionEnum.RU]: 'Russia',
  [JurisdictionEnum.SM]: 'San Marino',
  [JurisdictionEnum.RS]: 'Serbia',
  [JurisdictionEnum.SK]: 'Slovakia',
  [JurisdictionEnum.SI]: 'Slovenia',
  [JurisdictionEnum.ES]: 'Spain',
  [JurisdictionEnum.SE]: 'Sweden',
  [JurisdictionEnum.CH]: 'Switzerland',
  [JurisdictionEnum.UA]: 'Ukraine',
  [JurisdictionEnum.UK]: 'United Kingdom',
  [JurisdictionEnum.VA]: 'Vatican City',

  // North America
  [JurisdictionEnum.AG]: 'Antigua and Barbuda',
  [JurisdictionEnum.BS]: 'Bahamas',
  [JurisdictionEnum.BB]: 'Barbados',
  [JurisdictionEnum.BZ]: 'Belize',
  [JurisdictionEnum.CA]: 'Canada',
  [JurisdictionEnum.CR]: 'Costa Rica',
  [JurisdictionEnum.CU]: 'Cuba',
  [JurisdictionEnum.DM]: 'Dominica',
  [JurisdictionEnum.DO]: 'Dominican Republic',
  [JurisdictionEnum.SV]: 'El Salvador',
  [JurisdictionEnum.GD]: 'Grenada',
  [JurisdictionEnum.GT]: 'Guatemala',
  [JurisdictionEnum.HT]: 'Haiti',
  [JurisdictionEnum.HN]: 'Honduras',
  [JurisdictionEnum.JM]: 'Jamaica',
  [JurisdictionEnum.MX]: 'Mexico',
  [JurisdictionEnum.NI]: 'Nicaragua',
  [JurisdictionEnum.PA]: 'Panama',
  [JurisdictionEnum.KN]: 'Saint Kitts and Nevis',
  [JurisdictionEnum.LC]: 'Saint Lucia',
  [JurisdictionEnum.VC]: 'Saint Vincent and the Grenadines',
  [JurisdictionEnum.TT]: 'Trinidad and Tobago',
  [JurisdictionEnum.US]: 'United States',

  // South America
  [JurisdictionEnum.AR]: 'Argentina',
  [JurisdictionEnum.BO]: 'Bolivia',
  [JurisdictionEnum.BR]: 'Brazil',
  [JurisdictionEnum.CL]: 'Chile',
  [JurisdictionEnum.CO]: 'Colombia',
  [JurisdictionEnum.EC]: 'Ecuador',
  [JurisdictionEnum.GY]: 'Guyana',
  [JurisdictionEnum.PY]: 'Paraguay',
  [JurisdictionEnum.PE]: 'Peru',
  [JurisdictionEnum.SR]: 'Suriname',
  [JurisdictionEnum.UY]: 'Uruguay',
  [JurisdictionEnum.VE]: 'Venezuela',

  // Oceania
  [JurisdictionEnum.AU]: 'Australia',
  [JurisdictionEnum.FJ]: 'Fiji',
  [JurisdictionEnum.KI]: 'Kiribati',
  [JurisdictionEnum.MH]: 'Marshall Islands',
  [JurisdictionEnum.FM]: 'Micronesia',
  [JurisdictionEnum.NR]: 'Nauru',
  [JurisdictionEnum.NZ]: 'New Zealand',
  [JurisdictionEnum.PW]: 'Palau',
  [JurisdictionEnum.PG]: 'Papua New Guinea',
  [JurisdictionEnum.WS]: 'Samoa',
  [JurisdictionEnum.SB]: 'Solomon Islands',
  [JurisdictionEnum.TO]: 'Tonga',
  [JurisdictionEnum.TV]: 'Tuvalu',
  [JurisdictionEnum.VU]: 'Vanuatu',
};

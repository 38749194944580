import {
  Bookmark as BookmarkIcon,
  CaretDown as CaretDownIcon,
  CaretUp as CaretUpIcon,
  Hash as HashIcon,
} from '@phosphor-icons/react';
import { useState } from 'react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { Spinner, SpinnerBlock } from '../../../../components/Spinner';
import { numberAwareTextSort } from '../../../../utils/text';
import type { ResponseType as WorkspaceCompaniesResponseType } from '../../endpoints/WorkspaceCompaniesEndpoint';
import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';

type Company = WorkspaceCompaniesResponseType['companies'][0];

interface IWorkspaceCompaniesListProps {
  companies: Company[];
}

export const workspaceCompaniesSort = (a: Company, b: Company) => {
  const refDiff = b.referenceCount - a.referenceCount;
  if (refDiff === 0) {
    return numberAwareTextSort(a.name, b.name);
  } else {
    return refDiff;
  }
};

export const WorkspaceCompaniesList: React.FC<IWorkspaceCompaniesListProps> = (props) => {
  const { companies } = props;
  const [detailView, setDetailView] = useState<string | null>(null);

  return (
    <div className="grid gap-2">
      {companies.sort(workspaceCompaniesSort).map((company) => {
        return (
          <div key={company.id} className="bg-gray-100 rounded py-1 px-2">
            <div className="flex justify-between items-center">
              <div className="flex text-md items-center gap-1">
                <div>{company.name}</div>

                {company.companyNumber && (
                  <div className="flex items-center">
                    <HashIcon size={16} />
                    <div>{company.companyNumber}</div>
                  </div>
                )}
              </div>

              <div className="flex gap-2">
                <Tooltip text={`Referenced in ${company.referenceCount} documents`}>
                  <div className="flex items-center gap-1">
                    <div>{company.referenceCount}</div>
                    <BookmarkIcon size={16} />
                  </div>
                </Tooltip>

                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    setDetailView((v) => {
                      if (v === company.id) {
                        return null;
                      } else {
                        return company.id;
                      }
                    });
                  }}
                >
                  {detailView === company.id ? <CaretUpIcon size={16} /> : <CaretDownIcon size={16} />}
                </div>
              </div>
            </div>

            {detailView === company.id && (
              <div className="mt-2">
                {company.aliases.length > 0 && (
                  <div>
                    <span className="font-medium">Aliases</span>: {company.aliases.join(', ')}
                  </div>
                )}
                {company.companyNumberAliases.length > 0 && (
                  <div>
                    <span className="font-medium">Company numbers</span>: {company.companyNumberAliases.join(', ')}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const WorkspaceCompaniesPage = () => {
  const { companies, processingState, isFetching } = useWorkspace();

  if (processingState.processedCount < processingState.totalCount && !companies.length) {
    return <SpinnerBlock message="Still processing documents..." />;
  }

  if (!companies.length && isFetching) {
    return <SpinnerBlock message="Loading company data..." />;
  }

  const isProcessing = processingState.processedCount < processingState.totalCount;

  return (
    <div className="page-content">
      <PageHeader title="Companies" />

      <div className="mb-4 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Companies',
            },
          ]}
        />
      </div>

      <div>
        {isProcessing && (
          <div className="flex gap-1 items-center mb-2">
            <Spinner size={4} />
            Some documents are still being processed...
          </div>
        )}

        {!isProcessing && !companies.length && <div className="card">No companies found</div>}

        <div className="mb-4">
          <WorkspaceCompaniesList companies={companies} />
        </div>
      </div>
    </div>
  );
};

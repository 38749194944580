import React, { useCallback } from 'react';
import classNames from '@/utils/classnames';
import { Check as CheckIcon, CaretDown as ChevronDownIcon } from '@phosphor-icons/react';

import useClickOutside from '../../hooks/useClickOutside';

interface ISelectItemProps {
  children: React.ReactNode;
  value: string;
  selected: boolean | null;
  className?: string;
  onSelect: (value: string) => void;
}

const SelectItem = React.forwardRef<HTMLDivElement | null, ISelectItemProps>((props, forwardedRef) => {
  const { children, selected, onSelect, className, value } = props;

  return (
    <div
      className={classNames(
        'relative cursor-pointer select-none py-2 pl-3 pr-9 focus:outline-none hover:bg-gray-300 focus:bg-gray-300',
        className,
      )}
      onClick={() => {
        onSelect(value);
      }}
      ref={forwardedRef}
    >
      <div>{children}</div>
      {selected && (
        <div className={classNames('absolute inset-y-0 right-0 flex items-center pr-2 text-dark-500')}>
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      )}
    </div>
  );
});

const ItemLabel: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  return <div className="rounded bg-gray-300 px-1 whitespace-nowrap">{children}</div>;
};

export interface IMultiSelectProps<V extends Record<string, any>> {
  placeholder?: string;
  items: V[];
  selectedItems: V[];
  onSelectionChange: (value: V[]) => void;
  keyName: string;
  display: (value: V) => string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
}

export function MultiSelect<V extends Record<string, any>>(props: IMultiSelectProps<V>) {
  const { placeholder, keyName, items, display, selectedItems, onSelectionChange, isDisabled, isInvalid } = props;
  const [key, setKey] = React.useState(0);
  const [showItems, setShowItems] = React.useState(false);
  const containerRef = useClickOutside(
    useCallback(() => {
      setShowItems(false);
    }, [setShowItems]),
  );

  const handleItemSelect = useCallback(
    (value: string) => {
      if (isDisabled) {
        return;
      }

      const item = items.find((v) => v[keyName] === value);
      if (!item) {
        console.warn('Item not found');
        return;
      }

      if (!selectedItems.find((v) => v[keyName] === value)) {
        onSelectionChange([...selectedItems, item]);
      } else {
        onSelectionChange(selectedItems.filter((v) => v[keyName] !== value));
      }
    },
    [selectedItems, onSelectionChange, key, setKey, isDisabled],
  );

  return (
    <div className="w-full flex gap-1">
      <div className="w-full relative" ref={containerRef}>
        <button
          type="button"
          disabled={isDisabled}
          className={classNames(
            'flex justify-between items-center w-full border border-transparent focus:outline-none rounded-lg bg-gray-200 p-2 focus:border-gray-300',
            {
              'border-transparent': !isInvalid,
              'border-feedback-negative': isInvalid,
            },
          )}
          onClick={() => {
            setShowItems(!showItems);
          }}
        >
          <div className="pr-2">
            {selectedItems.length ? (
              <div className="flex gap-2">
                {selectedItems.slice(0, 3).map((v) => (
                  <ItemLabel key={`placeholder-${v[keyName]}`}>{display(v)}</ItemLabel>
                ))}

                {selectedItems.length > 3 && <ItemLabel>{`+ ${selectedItems.length - 3} other`}</ItemLabel>}
              </div>
            ) : (
              <span className="text-gray-400">{placeholder}</span>
            )}
          </div>
          <div>
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </button>

        {showItems && (
          <div className="absolute z-dropdown mt-1 max-h-60 w-full overflow-auto bg-gray-200 py-1 text-dark-500 focus:outline-none rounded-lg border border-gray-300">
            {items.map((item) => {
              const itemKey = item[keyName];
              const isSelected = selectedItems.find((v) => v[keyName] === itemKey) !== undefined;

              return (
                <SelectItem key={itemKey} value={itemKey} selected={isSelected} onSelect={handleItemSelect}>
                  {display(item)}
                </SelectItem>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

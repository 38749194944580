import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { useWorkspace } from '../context/WorkspaceContext';
import { useMemo } from 'react';

export interface IWorkspacePermissionWrapperProps {
  allowedPermissions: WorkspacePermissionEnum[];
  isOwner?: boolean;
  children: React.ReactNode;
}

export const WorkspacePermissionWrapper: React.FC<IWorkspacePermissionWrapperProps> = (props) => {
  const { children, allowedPermissions, isOwner } = props;
  const { workspace } = useWorkspace();

  const hasPermission = useMemo(() => {
    return allowedPermissions.some((permission) => workspace.permissions.includes(permission));
  }, [workspace.permissions, allowedPermissions]);

  if (isOwner) {
    if (workspace.isOwner) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
};

import classNames from '@/utils/classnames';
import { Check as CheckIcon } from '@phosphor-icons/react';

export interface IStyledCheckboxProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const StyledCheckbox: React.FC<IStyledCheckboxProps> = (props) => {
  const { isChecked, onChange } = props;

  return (
    <div
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        onChange(!isChecked);
      }}
    >
      <div
        className={classNames('relative h-4 w-4 rounded cursor-pointer flex items-center justify-center', {
          'bg-primary-800 hover:bg-primary-900 text-white': isChecked,
          'bg-gray-300 hover:bg-gray-400 text-gray-300': !isChecked,
        })}
      >
        <CheckIcon
          className="h-3 w-3"
          style={{
            top: 1,
            left: 1,
          }}
        />
      </div>
    </div>
  );
};

import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { nullthrows } from '../../../utils/invariant';
import { AddonSelectionField } from '../components/AddonSelectionField';
import { InputDialog } from '../../../components/dialog/InputDialog';
import { DataField } from '../../../components/DataField';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as InternalTeamResponseType } from '../endpoints/InternalTeamEndpoint';
import type {
  ResponseType as DeleteTeamResponseType,
  BodyType as DeleteTeamBodyType,
} from '../endpoints/DeleteTeamEndpoint';
import type {
  ResponseType as InternalUpdateTeamResponseType,
  BodyType as InternalUpdateTeamBodyType,
} from '../endpoints/InternalUpdateTeamEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { captureException } from '@sentry/react';

const updateTeamSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
});

export const InternalTeamPage = () => {
  const navigate = useNavigate();
  const { internalTeamId: _teamId } = useParams<{ internalTeamId: string }>();
  const teamId = nullthrows(_teamId, 'Team ID is required');

  const { data, isLoading, mutate } = useSWR<InternalTeamResponseType>(
    `/api/v1/team/internal-data/${teamId}`,
    fetchEndpointData,
  );

  if (!data?.team && isLoading) {
    return <SpinnerBlock message="Loading team data..." />;
  }

  const team = nullthrows(data?.team, 'Team not found');
  const initialValues = {
    name: team.name,
    language: team.language,
    enabledAddons: team.enabledAddons,
    uploadLimit: `${team.uploadLimit}`,
    fileSizeLimit: `${team.fileSizeLimit}`,
  };

  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Team Settings" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Teams',
              to: '..',
            },
            {
              name: 'Team',
            },
          ]}
        />

        <div className="flex gap-2">
          <InputDialog
            triggerText="Add Credits"
            title="Add Credits"
            description="Enter a new name for the folder"
            labelText="Credits"
            submitText="Add Credits"
            initialValue="0"
            onSubmit={async (value) => {
              try {
                const parsedAmount = parseInt(value, 10);
                if (isNaN(parsedAmount) || parsedAmount <= 0) {
                  throw new Error('Invalid amount');
                }

                await fetchEndpointData(`/api/v1/team/add-credits`, {
                  method: 'POST',
                  body: {
                    teamId: team.id,
                    amount: parsedAmount * 1000,
                  },
                });
                mutate();
                toast.success('Credits have been added to the team');
              } catch (err) {
                captureException(err);
                toast.error('Could not add credits to the team: ' + getDisplayError(err));
              }
            }}
          />

          <ConfirmDialog
            triggerText="Delete Team"
            title="Delete Team"
            submitText="Delete"
            triggerVariant="destructive"
            description={`Are you sure you want to delete the team ${team.name}?`}
            onSubmit={async () => {
              try {
                const payload: DeleteTeamBodyType = {
                  teamId: team.id,
                };
                await fetchEndpointData<DeleteTeamResponseType>(`/api/v1/team/delete`, {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Team has been deleted');
                navigate('/');
              } catch (err) {
                captureException(err);
                toast.error('Could not delete team: ' + getDisplayError(err));
              }
            }}
          />
        </div>
      </div>

      <div className="card">
        <div className="mb-4">
          <DataField title="Remaining credits">{Math.round(team.remainingCredits / 1000)}</DataField>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={updateTeamSchema}
          onSubmit={async (values) => {
            try {
              const payload: InternalUpdateTeamBodyType = {
                teamId: team.id,
                data: {
                  ...values,
                  uploadLimit: parseInt(values.uploadLimit, 10),
                  fileSizeLimit: parseInt(values.fileSizeLimit, 10),
                },
              };
              await fetchEndpointData<InternalUpdateTeamResponseType>(`/api/v1/team/internal-update`, {
                method: 'POST',
                body: payload,
              });
              mutate();
              toast.success('Team updated');
            } catch (err: any) {
              captureException(err);
              toast.error('Could not update team: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <LanguageSelectField labelText="Language" name="language" />

              <InputField labelText="Upload size limit" type="number" name="uploadLimit" />

              <InputField labelText="File size limit" type="number" name="fileSizeLimit" />

              <AddonSelectionField labelText="Enabled Addons" name="enabledAddons" />

              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Update Team
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

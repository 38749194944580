import { useField } from 'formik';

import { InputWrapper } from './InputWrapper';
import { ISimpleSelectItem, SimpleSelect } from './select/SimpleSelect';
import { LanguageEnum } from '../app/workspaceDocument/enums';

export const LANG_OPTIONS: ISimpleSelectItem[] = [
  {
    key: '' + LanguageEnum.NL,
    name: 'Nederlands',
  },
  {
    key: '' + LanguageEnum.EN,
    name: 'English',
  },
  {
    key: '' + LanguageEnum.FR,
    name: 'Français',
  },
  {
    key: '' + LanguageEnum.DE,
    name: 'Deutsch',
  },
];

export interface ILanguageSelectFieldProps {
  name: string;
  labelText: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const LanguageSelectField: React.FC<ILanguageSelectFieldProps> = (props) => {
  const { labelText, helperText, isDisabled, name } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <SimpleSelect
        items={LANG_OPTIONS}
        selectedItem={LANG_OPTIONS.find((v) => v.key === field.value) ?? null}
        onSelect={(val) => {
          helpers.setValue(val?.key ?? null);
        }}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    </InputWrapper>
  );
};

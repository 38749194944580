import { useEffect, useState } from 'react';
import { TREE_ROOT_ID, TreeNode } from '../tree/WorkspaceDocumentTree';
import { useThrottle } from '@/hooks/useThrottle';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import useSWR from 'swr';
import type {
  BodyType as SearchPayloadType,
  ResponseType as SearchResponseType,
} from '../endpoints/SearchWorkspaceDocumentChunksEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';

export interface ISearchResult {
  id: string;
  node: TreeNode;
  score: number;
  index: number;
  chunkCount: number;
  chunkIds: string[];
}

function normalizeNameForSearch(name: string) {
  return name
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[^0-9a-z\s\-_]/g, '')
    .replace(/[\s\-_]+/g, ' ');
}

async function executeSearch([query, workspaceId, take, folderIds]: [
  string,
  string,
  number,
  (string | null)[],
]): Promise<SearchResponseType> {
  if (!query) {
    return {
      query: '',
      hits: [],
    };
  }

  const payload: SearchPayloadType = {
    q: query,
    workspaceId,
    take,
    folderIds: folderIds.map((v) => (v === TREE_ROOT_ID ? null : v)),
  };
  const result = await fetchEndpointData<SearchResponseType>(`/api/v1/workspace/document/search`, {
    method: 'POST',
    body: payload,
  });
  return result;
}

export function useSearch(query: string, folderId: string) {
  const [persistedSearchQuery, setPersistedSearchQuery] = useState(query);
  const { tree, workspace } = useWorkspace();
  const { data, isLoading: _isLoading } = useSWR([persistedSearchQuery, workspace.id, 100, [folderId]], executeSearch);

  useThrottle(
    () => {
      setPersistedSearchQuery(query);
    },
    query,
    500,
  );

  const [results, setResults] = useState<{
    query: string;
    hits: ISearchResult[];
  }>({
    query: '',
    hits: [],
  });

  useEffect(() => {
    if (!query) {
      setResults({
        query,
        hits: [],
      });
    }
  }, [query]);

  useEffect(() => {
    if (!data || data.query !== persistedSearchQuery) {
      return;
    }

    const resultsMap = new Map<string, ISearchResult & { index: number }>();

    const normalizedQuery = normalizeNameForSearch(persistedSearchQuery);

    const documentIds = tree.getChildDocuments(folderId, true);
    const documents = documentIds.map((id) => tree.getNode(id)).filter((v) => !!v);
    const matchingDocuments = documents
      .filter((d) => {
        const normalizedName = normalizeNameForSearch(d.name);
        return normalizedName.includes(normalizedQuery);
      })
      .sort((a, b) => a.name.length - b.name.length);

    const folderIds = tree.getChildFolders(folderId);
    const folders = folderIds
      .filter((v) => v !== TREE_ROOT_ID)
      .map((id) => tree.getNode(id))
      .filter((v) => !!v);
    const matchingFolders = folders
      .filter((d) => {
        const normalizedName = normalizeNameForSearch(d.name);
        return normalizedName.includes(normalizedQuery);
      })
      .sort((a, b) => a.name.length - b.name.length);

    for (let idx = 0; idx < matchingFolders.length; idx++) {
      const node = matchingFolders[idx]!;

      resultsMap.set(node.id, {
        id: node.id,
        node,
        index: resultsMap.size,
        chunkCount: 0,
        chunkIds: [],
        score: (matchingFolders.length - idx) * 100,
      });
    }

    for (let idx = 0; idx < matchingDocuments.length; idx++) {
      const node = matchingDocuments[idx]!;

      resultsMap.set(node.id, {
        id: node.id,
        node,
        index: resultsMap.size,
        chunkCount: 0,
        chunkIds: [],
        score: (matchingDocuments.length - idx) * 1000,
      });
    }

    for (const hit of data.hits) {
      const existingResult = resultsMap.get(hit.documentId);
      if (existingResult) {
        existingResult.score = existingResult.score + hit.score * 100_000;
        existingResult.chunkCount = existingResult.chunkCount + hit.chunkCount;
        existingResult.chunkIds = [...new Set([...existingResult.chunkIds, ...hit.chunkIds])];
      } else {
        const node = tree.getNode(hit.documentId);
        if (!node) {
          continue;
        }

        resultsMap.set(hit.documentId, {
          id: hit.documentId,
          node,
          score: hit.score,
          index: resultsMap.size,
          chunkCount: hit.chunkCount,
          chunkIds: hit.chunkIds,
        });
      }
    }

    setResults({
      query: persistedSearchQuery,
      hits: Array.from(resultsMap.values()).sort((a, b) => b.score - a.score),
    });
  }, [data, persistedSearchQuery]);

  const isLoadingResults = query && results.query !== query;
  return {
    isLoading: isLoadingResults,
    results: query ? results.hits : [],
  };
}

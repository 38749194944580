import React, { useCallback, useState } from 'react';
import { Info as CircleHelpIcon } from '@phosphor-icons/react';

import { BaseButton } from '../button/BaseButton';
import { Button, ButtonProps } from '../button/Button';
import { DialogContent, DialogRoot } from './Dialog';

export interface IConfirmDialogProps {
  hideTrigger?: boolean;
  triggerSize?: ButtonProps['size'];
  triggerText?: React.ReactNode;
  triggerVariant?: ButtonProps['variant'];
  title: string;
  children?: React.ReactNode;
  submitText?: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

export const InfoDialog: React.FC<IConfirmDialogProps> = (props) => {
  const {
    hideTrigger,
    triggerSize = 6,
    triggerText = <CircleHelpIcon className="w-4 h-4" />,
    triggerVariant = 'ghost',
    title,
    submitText = 'Ok',
    children,
  } = props;
  const [_open, _setOpen] = useState(false);

  const isOpen = props.isOpen ?? _open;
  const setOpen = useCallback(
    (newOpen: boolean) => {
      if (props.setIsOpen) {
        props.setIsOpen(newOpen);
      } else {
        _setOpen(newOpen);
      }
    },
    [isOpen, props.setIsOpen],
  );

  return (
    <DialogRoot open={isOpen} onOpenChange={setOpen}>
      {!hideTrigger && (
        <Button
          size={triggerSize}
          variant={triggerVariant}
          title={title}
          onTrigger={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            setOpen(true);
          }}
        >
          {triggerText}
        </Button>
      )}

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">{title}</h1>

        <div className="mb-4">{children}</div>

        <div className="flex justify-end">
          <div>
            <BaseButton
              variant="primary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setOpen(false);
              }}
            >
              {submitText}
            </BaseButton>
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

import { Outlet } from 'react-router-dom';

import { TeamProvider } from '../context/TeamContext';
import { CategoriesProvider } from '../../category/contexts/CategoriesContext';

export const TeamWrapper = () => {
  return (
    <TeamProvider>
      <CategoriesProvider>
        <Outlet />
      </CategoriesProvider>
    </TeamProvider>
  );
};

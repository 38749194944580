import React from 'react';

import { FlaggingDialog } from '../../workspaceDocument/components/FlaggingDialog';

export interface IChatMessageActionsProps {
  messageId: string;
}

export const ChatMessageActions: React.FC<IChatMessageActionsProps> = (props) => {
  const { messageId } = props;

  return (
    <div>
      <FlaggingDialog messageId={messageId} />
    </div>
  );
};

import { House as HomeIcon } from '@phosphor-icons/react';

import { LinkButton } from '../button/Button';
import { UserMenu } from '../UserMenu';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const UserDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;

  return (
    <div>
      <header className="flex justify-between p-4 border-b border-b-dark-05">
        <div className="flex gap-2">
          <LinkButton to="/app">
            <HomeIcon className="button-icon" />
          </LinkButton>
        </div>

        <UserMenu />
      </header>

      <main style={{ minHeight: 'calc(100vh - 200px)' }}>{children}</main>
    </div>
  );
};

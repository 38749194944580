import { UploadSimple as UploadIcon } from '@phosphor-icons/react';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Button } from '@/components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';

export interface IUploadButtonProps {
  folderId: string | null;
  allowedTypes: string[];
}

export const UploadButton: React.FC<IUploadButtonProps> = (props) => {
  const { folderId, allowedTypes } = props;
  const { team } = useTeam();
  const { syncState } = useWorkspace();
  const inputEl = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let inputFiles = evt.target.files;
    if (!inputFiles) {
      return;
    }

    if (inputFiles.length > 0) {
      for (const file of inputFiles) {
        const mbSize = file.size / 1024 / 1024;
        if (mbSize > team.uploadLimit) {
          toast.error(`File size ${mbSize}MB exceeds the upload limit ${team.uploadLimit}MB`);
          continue;
        }

        syncState.startUpload({
          folderId,
          file,
        });
      }
    }

    evt.target.value = '';
  };

  return (
    <div>
      <input
        type="file"
        multiple={true}
        accept={allowedTypes.join(', ')}
        onChange={handleFileUpload}
        className="hidden"
        ref={inputEl}
      ></input>

      <Button
        size={8}
        onTrigger={() => {
          if (inputEl.current) {
            // @ts-ignore
            inputEl.current.dispatchEvent(new MouseEvent('click'));
          }
        }}
      >
        <div className="flex items-center gap-1">
          <UploadIcon className="button-icon" />
          Upload Files
        </div>
      </Button>
    </div>
  );
};

import { classNames } from '@/utils/classnames';
import { Check as CheckIcon } from '@phosphor-icons/react';
import { useId } from 'react';

export interface ICheckButton {
  labelText: string;
  isChecked?: boolean;
  onCheck?: (newValue: boolean) => void;
}

export const CheckButton: React.FC<ICheckButton> = (props) => {
  const { labelText, isChecked, onCheck } = props;
  const id = useId();

  return (
    <label
      className={classNames('flex gap-2 items-center rounded px-1 cursor-pointer', {
        'bg-blue-50 hover:bg-blue-100': isChecked,
        'bg-gray-200 hover:bg-gray-300': !isChecked,
      })}
      htmlFor={id}
    >
      <input
        type="checkbox"
        className="visually-hidden"
        name={id}
        id={id}
        checked={!!isChecked}
        onChange={(evt) => {
          onCheck?.(evt.target.checked);
        }}
      />

      <div className="flex w-full justify-between">
        <div>{labelText}</div>
        {!!isChecked && (
          <div className="mt-1 mr-1">
            <CheckIcon className="w-4 h-4" />
          </div>
        )}
      </div>
    </label>
  );
};

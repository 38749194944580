import { Navigate, useParams } from 'react-router-dom';

import { useTeam } from '@/app/team/context/TeamContext';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { SpinnerBlock } from '@/components/Spinner';
import { nullthrows } from '@/utils/invariant';

export const WorkspaceDocumentRedirectPage = () => {
  const { documentId: _documentId } = useParams<{
    documentId: string;
  }>();
  const documentId = nullthrows(_documentId, 'documentId not defined in params');
  const { team } = useTeam();
  const { tree, workspace } = useWorkspace();

  if (tree.isSyncing) {
    return <SpinnerBlock message="Loading..." />;
  }

  const documentNode = tree.getDocumentNode(documentId);
  if (!documentNode) {
    return <Navigate replace={true} to={`/app/t/${team.id}/workspace/${workspace.id}/documents`} />;
  } else {
    return (
      <Navigate
        replace={true}
        to={`/app/t/${team.id}/workspace/${workspace.id}/documents/${documentNode.parentFolderId}/${documentNode.id}`}
      />
    );
  }
};

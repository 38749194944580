// !!! This is used on server and client side !!!

export enum DocumentIndexingStatus {
  Created = 'Created', // New record, pending embeddings generation
  Pending = 'Pending', // Embeddings have been generated, pending indexation
  Indexing = 'Indexing', // Indexing, awaiting task completion
  Invalid = 'Invalid', // Invalid document, cannot be indexed
  Indexed = 'Indexed', // Indexed, all done.
}

const DOC_INDEXING_MATCHING_SET = new Map(
  Object.values(DocumentIndexingStatus).map((v) => {
    return [v.toLowerCase(), v];
  }),
);

export function getDocumentIndexingStatus(value: string): DocumentIndexingStatus {
  return DOC_INDEXING_MATCHING_SET.get(value.trim().toLowerCase()) || DocumentIndexingStatus.Created;
}

export enum DocumentFailureReason {
  InvalidFile = 'InvalidFile',
  UnsupportedFormat = 'UnsupportedFormat',
  TooManyCharacters = 'TooManyCharacters',
  FileSizeTooLarge = 'FileSizeTooLarge',
  Other = 'Other',
}

const DOC_FAILURE_MATCHING_SET = new Map(
  Object.values(DocumentFailureReason).map((v) => {
    return [v.toLowerCase(), v];
  }),
);

export function getDocumentFailureReason(value: string): DocumentFailureReason {
  return DOC_FAILURE_MATCHING_SET.get(value.trim().toLowerCase()) || DocumentFailureReason.Other;
}

export enum LanguageEnum {
  AA = 'aa', // Afar
  AB = 'ab', // Abkhazian
  AF = 'af', // Afrikaans
  AM = 'am', // Amharic
  AR = 'ar', // Arabic
  AS = 'as', // Assamese
  AY = 'ay', // Aymara
  AZ = 'az', // Azerbaijani
  BA = 'ba', // Bashkir
  BE = 'be', // Belarusian
  BG = 'bg', // Bulgarian
  BH = 'bh', // Bihari
  BI = 'bi', // Bislama
  BN = 'bn', // Bengali
  BO = 'bo', // Tibetan
  BR = 'br', // Breton
  CA = 'ca', // Catalan
  CO = 'co', // Corsican
  CS = 'cs', // Czech
  CY = 'cy', // Welsh
  DA = 'da', // Danish
  DE = 'de', // German
  DZ = 'dz', // Dzongkha
  EL = 'el', // Greek
  EN = 'en', // English
  EO = 'eo', // Esperanto
  ES = 'es', // Spanish
  ET = 'et', // Estonian
  EU = 'eu', // Basque
  FA = 'fa', // Persian
  FI = 'fi', // Finnish
  FJ = 'fj', // Fijian
  FO = 'fo', // Faroese
  FR = 'fr', // French
  FY = 'fy', // Western Frisian
  GA = 'ga', // Irish
  GD = 'gd', // Scottish Gaelic
  GL = 'gl', // Galician
  GN = 'gn', // Guarani
  GU = 'gu', // Gujarati
  HA = 'ha', // Hausa
  HE = 'he', // Hebrew
  HI = 'hi', // Hindi
  HR = 'hr', // Croatian
  HU = 'hu', // Hungarian
  HY = 'hy', // Armenian
  IA = 'ia', // Interlingua
  ID = 'id', // Indonesian
  IE = 'ie', // Interlingue
  IK = 'ik', // Inupiaq
  IS = 'is', // Icelandic
  IT = 'it', // Italian
  IU = 'iu', // Inuktitut
  JA = 'ja', // Japanese
  JV = 'jv', // Javanese
  KA = 'ka', // Georgian
  KK = 'kk', // Kazakh
  KL = 'kl', // Kalaallisut
  KM = 'km', // Khmer
  KN = 'kn', // Kannada
  KO = 'ko', // Korean
  KS = 'ks', // Kashmiri
  KU = 'ku', // Kurdish
  KY = 'ky', // Kyrgyz
  LA = 'la', // Latin
  LN = 'ln', // Lingala
  LO = 'lo', // Lao
  LT = 'lt', // Lithuanian
  LV = 'lv', // Latvian
  MG = 'mg', // Malagasy
  MI = 'mi', // Maori
  MK = 'mk', // Macedonian
  ML = 'ml', // Malayalam
  MN = 'mn', // Mongolian
  MO = 'mo', // Moldavian
  MR = 'mr', // Marathi
  MS = 'ms', // Malay
  MT = 'mt', // Maltese
  MY = 'my', // Burmese
  NA = 'na', // Nauru
  NE = 'ne', // Nepali
  NL = 'nl', // Dutch
  NO = 'no', // Norwegian
  OC = 'oc', // Occitan
  OM = 'om', // Oromo
  OR = 'or', // Oriya
  PA = 'pa', // Punjabi
  PL = 'pl', // Polish
  PS = 'ps', // Pashto
  PT = 'pt', // Portuguese
  QU = 'qu', // Quechua
  RM = 'rm', // Romansh
  RN = 'rn', // Kirundi
  RO = 'ro', // Romanian
  RU = 'ru', // Russian
  RW = 'rw', // Kinyarwanda
  SA = 'sa', // Sanskrit
  SD = 'sd', // Sindhi
  SG = 'sg', // Sango
  SH = 'sh', // Serbo-Croatian
  SI = 'si', // Sinhala
  SK = 'sk', // Slovak
  SL = 'sl', // Slovenian
  SM = 'sm', // Samoan
  SN = 'sn', // Shona
  SO = 'so', // Somali
  SQ = 'sq', // Albanian
  SR = 'sr', // Serbian
  SS = 'ss', // Swati
  ST = 'st', // Southern Sotho
  SU = 'su', // Sundanese
  SV = 'sv', // Swedish
  SW = 'sw', // Swahili
  TA = 'ta', // Tamil
  TE = 'te', // Telugu
  TG = 'tg', // Tajik
  TH = 'th', // Thai
  TI = 'ti', // Tigrinya
  TK = 'tk', // Turkmen
  TL = 'tl', // Tagalog
  TN = 'tn', // Tswana
  TO = 'to', // Tonga
  TR = 'tr', // Turkish
  TS = 'ts', // Tsonga
  TT = 'tt', // Tatar
  TW = 'tw', // Twi
  UG = 'ug', // Uyghur
  UK = 'uk', // Ukrainian
  UR = 'ur', // Urdu
  UZ = 'uz', // Uzbek
  VI = 'vi', // Vietnamese
  VO = 'vo', // Volapük
  WO = 'wo', // Wolof
  XH = 'xh', // Xhosa
  YI = 'yi', // Yiddish
  YO = 'yo', // Yoruba
  ZA = 'za', // Zhuang
  ZH = 'zh', // Chinese
  ZU = 'zu', // Zulu
}

const LANGUAGE_VALUES_SET = new Set<string>(Object.values(LanguageEnum));
export function getLanguage(lang: string): LanguageEnum {
  const normalized = lang.toLowerCase();
  if (LANGUAGE_VALUES_SET.has(normalized)) {
    return normalized as LanguageEnum;
  } else {
    return LanguageEnum.EN;
  }
}

export const LANG_MAP: Record<string, string> = {
  aa: 'Afar',
  ab: 'Abkhazian',
  af: 'Afrikaans',
  am: 'Amharic',
  ar: 'Arabic',
  as: 'Assamese',
  ay: 'Aymara',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari',
  bi: 'Bislama',
  bn: 'Bengali',
  bo: 'Tibetan',
  br: 'Breton',
  ca: 'Catalan',
  co: 'Corsican',
  cs: 'Czech',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dz: 'Dzongkha',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  fi: 'Finnish',
  fj: 'Fijian',
  fo: 'Faroese',
  fr: 'French',
  fy: 'Western Frisian',
  ga: 'Irish',
  gd: 'Scottish Gaelic',
  gl: 'Galician',
  gn: 'Guarani',
  gu: 'Gujarati',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  hy: 'Armenian',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ik: 'Inupiaq',
  is: 'Icelandic',
  it: 'Italian',
  iu: 'Inuktitut',
  ja: 'Japanese',
  jv: 'Javanese',
  ka: 'Georgian',
  kk: 'Kazakh',
  kl: 'Kalaallisut',
  km: 'Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  ky: 'Kyrgyz',
  la: 'Latin',
  ln: 'Lingala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lv: 'Latvian',
  mg: 'Malagasy',
  mi: 'Maori',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mo: 'Moldavian',
  mr: 'Marathi',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauru',
  ne: 'Nepali',
  nl: 'Dutch',
  no: 'Norwegian',
  oc: 'Occitan',
  om: 'Oromo',
  or: 'Oriya',
  pa: 'Punjabi',
  pl: 'Polish',
  ps: 'Pashto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansh',
  rn: 'Kirundi',
  ro: 'Romanian',
  ru: 'Russian',
  rw: 'Kinyarwanda',
  sa: 'Sanskrit',
  sd: 'Sindhi',
  sg: 'Sango',
  sh: 'Serbo-Croatian',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  sq: 'Albanian',
  sr: 'Serbian',
  ss: 'Swati',
  st: 'Southern Sotho',
  su: 'Sundanese',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ug: 'Uyghur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang',
  zh: 'Chinese',
  zu: 'Zulu',
};

export enum JurisdictionEnum {
  // Africa
  DZ = 'dz', // Algeria
  AO = 'ao', // Angola
  BJ = 'bj', // Benin
  BW = 'bw', // Botswana
  BF = 'bf', // Burkina Faso
  BI = 'bi', // Burundi
  CM = 'cm', // Cameroon
  CV = 'cv', // Cape Verde
  CF = 'cf', // Central African Republic
  TD = 'td', // Chad
  KM = 'km', // Comoros
  CG = 'cg', // Congo
  CD = 'cd', // Congo, Democratic Republic of the
  CI = 'ci', // Côte d'Ivoire
  DJ = 'dj', // Djibouti
  EG = 'eg', // Egypt
  GQ = 'gq', // Equatorial Guinea
  ER = 'er', // Eritrea
  ET = 'et', // Ethiopia
  GA = 'ga', // Gabon
  GM = 'gm', // Gambia
  GH = 'gh', // Ghana
  GN = 'gn', // Guinea
  GW = 'gw', // Guinea-Bissau
  KE = 'ke', // Kenya
  LS = 'ls', // Lesotho
  LR = 'lr', // Liberia
  LY = 'ly', // Libya
  MG = 'mg', // Madagascar
  MW = 'mw', // Malawi
  ML = 'ml', // Mali
  MR = 'mr', // Mauritania
  MU = 'mu', // Mauritius
  MA = 'ma', // Morocco
  MZ = 'mz', // Mozambique
  NA = 'na', // Namibia
  NE = 'ne', // Niger
  NG = 'ng', // Nigeria
  RW = 'rw', // Rwanda
  ST = 'st', // São Tomé and Príncipe
  SN = 'sn', // Senegal
  SC = 'sc', // Seychelles
  SL = 'sl', // Sierra Leone
  SO = 'so', // Somalia
  ZA = 'za', // South Africa
  SS = 'ss', // South Sudan
  SD = 'sd', // Sudan
  SZ = 'sz', // Eswatini (formerly Swaziland)
  TZ = 'tz', // Tanzania
  TG = 'tg', // Togo
  TN = 'tn', // Tunisia
  UG = 'ug', // Uganda
  ZM = 'zm', // Zambia
  ZW = 'zw', // Zimbabwe

  // Asia
  AF = 'af', // Afghanistan
  AM = 'am', // Armenia
  AZ = 'az', // Azerbaijan
  BH = 'bh', // Bahrain
  BD = 'bd', // Bangladesh
  BT = 'bt', // Bhutan
  BN = 'bn', // Brunei
  KH = 'kh', // Cambodia
  CN = 'cn', // China
  CY = 'cy', // Cyprus
  GE = 'ge', // Georgia
  IN = 'in', // India
  ID = 'id', // Indonesia
  IR = 'ir', // Iran
  IQ = 'iq', // Iraq
  IL = 'il', // Israel
  JP = 'jp', // Japan
  JO = 'jo', // Jordan
  KZ = 'kz', // Kazakhstan
  KW = 'kw', // Kuwait
  KG = 'kg', // Kyrgyzstan
  LA = 'la', // Laos
  LB = 'lb', // Lebanon
  MY = 'my', // Malaysia
  MV = 'mv', // Maldives
  MN = 'mn', // Mongolia
  MM = 'mm', // Myanmar
  NP = 'np', // Nepal
  OM = 'om', // Oman
  PK = 'pk', // Pakistan
  PH = 'ph', // Philippines
  QA = 'qa', // Qatar
  SA = 'sa', // Saudi Arabia
  SG = 'sg', // Singapore
  KR = 'kr', // South Korea
  LK = 'lk', // Sri Lanka
  SY = 'sy', // Syria
  TW = 'tw', // Taiwan
  TJ = 'tj', // Tajikistan
  TH = 'th', // Thailand
  TL = 'tl', // Timor-Leste
  TR = 'tr', // Türkiye
  TM = 'tm', // Turkmenistan
  AE = 'ae', // United Arab Emirates
  UZ = 'uz', // Uzbekistan
  VN = 'vn', // Vietnam
  YE = 'ye', // Yemen

  // Europe
  AL = 'al', // Albania
  AD = 'ad', // Andorra
  AT = 'at', // Austria
  BY = 'by', // Belarus
  BE = 'be', // Belgium
  BA = 'ba', // Bosnia and Herzegovina
  BG = 'bg', // Bulgaria
  HR = 'hr', // Croatia
  CZ = 'cz', // Czech Republic
  DK = 'dk', // Denmark
  EE = 'ee', // Estonia
  FI = 'fi', // Finland
  FR = 'fr', // France
  DE = 'de', // Germany
  GR = 'gr', // Greece
  HU = 'hu', // Hungary
  IS = 'is', // Iceland
  IE = 'ie', // Ireland
  IT = 'it', // Italy
  XK = 'xk', // Kosovo
  LV = 'lv', // Latvia
  LI = 'li', // Liechtenstein
  LT = 'lt', // Lithuania
  LU = 'lu', // Luxembourg
  MT = 'mt', // Malta
  MD = 'md', // Moldova
  MC = 'mc', // Monaco
  ME = 'me', // Montenegro
  NL = 'nl', // Netherlands
  MK = 'mk', // North Macedonia
  NO = 'no', // Norway
  PL = 'pl', // Poland
  PT = 'pt', // Portugal
  RO = 'ro', // Romania
  RU = 'ru', // Russia
  SM = 'sm', // San Marino
  RS = 'rs', // Serbia
  SK = 'sk', // Slovakia
  SI = 'si', // Slovenia
  ES = 'es', // Spain
  SE = 'se', // Sweden
  CH = 'ch', // Switzerland
  UA = 'ua', // Ukraine
  UK = 'uk', // United Kingdom
  VA = 'va', // Vatican City

  // North America
  AG = 'ag', // Antigua and Barbuda
  BS = 'bs', // Bahamas
  BB = 'bb', // Barbados
  BZ = 'bz', // Belize
  CA = 'ca', // Canada
  CR = 'cr', // Costa Rica
  CU = 'cu', // Cuba
  DM = 'dm', // Dominica
  DO = 'do', // Dominican Republic
  SV = 'sv', // El Salvador
  GD = 'gd', // Grenada
  GT = 'gt', // Guatemala
  HT = 'ht', // Haiti
  HN = 'hn', // Honduras
  JM = 'jm', // Jamaica
  MX = 'mx', // Mexico
  NI = 'ni', // Nicaragua
  PA = 'pa', // Panama
  KN = 'kn', // Saint Kitts and Nevis
  LC = 'lc', // Saint Lucia
  VC = 'vc', // Saint Vincent and the Grenadines
  TT = 'tt', // Trinidad and Tobago
  US = 'us', // United States

  // South America
  AR = 'ar', // Argentina
  BO = 'bo', // Bolivia
  BR = 'br', // Brazil
  CL = 'cl', // Chile
  CO = 'co', // Colombia
  EC = 'ec', // Ecuador
  GY = 'gy', // Guyana
  PY = 'py', // Paraguay
  PE = 'pe', // Peru
  SR = 'sr', // Suriname
  UY = 'uy', // Uruguay
  VE = 've', // Venezuela

  // Oceania
  AU = 'au', // Australia
  FJ = 'fj', // Fiji
  KI = 'ki', // Kiribati
  MH = 'mh', // Marshall Islands
  FM = 'fm', // Micronesia
  NR = 'nr', // Nauru
  NZ = 'nz', // New Zealand
  PW = 'pw', // Palau
  PG = 'pg', // Papua New Guinea
  WS = 'ws', // Samoa
  SB = 'sb', // Solomon Islands
  TO = 'to', // Tonga
  TV = 'tv', // Tuvalu
  VU = 'vu', // Vanuatu
}

const JURISDICTION_VALUES_SET = new Set<string>(Object.values(JurisdictionEnum));
export function getJurisdiction(jurisdiction: string): JurisdictionEnum {
  const normalized = jurisdiction.toLowerCase();
  if (JURISDICTION_VALUES_SET.has(normalized)) {
    return normalized as JurisdictionEnum;
  } else {
    return JurisdictionEnum.BE;
  }
}

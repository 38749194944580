import { captureException } from '@sentry/react';
import { ArrowSquareRight as ArrowSquareRightIcon } from '@phosphor-icons/react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { DocumentPickerDialogWithTrigger } from './DocumentPicker';
import { BodyType as MoveDocumentPayload } from '../endpoints/MoveDocumentEndpoint';
import { BodyType as MoveFolderPayload } from '../../workspaceFolder/endpoints/MoveWorkspaceFolderEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TREE_ROOT_ID } from '../tree/WorkspaceDocumentTree';
import { getDisplayError } from '@/utils/get-display-error';

export interface IMoveDialogProps {
  selectedFolderIds: string[];
  selectedDocumentIds: string[];
  onComplete: () => void;
}

export const MoveDialog: React.FC<IMoveDialogProps> = (props) => {
  const { selectedFolderIds, selectedDocumentIds, onComplete } = props;
  const { workspace, tree } = useWorkspace();
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  return (
    <DocumentPickerDialogWithTrigger
      triggerSize={8}
      triggerText={
        <div className="flex items-center gap-1">
          <ArrowSquareRightIcon className="button-icon" />
          Move
        </div>
      }
      isLoading={isFetching}
      isDisabled={!selectedFolderIds.length && !selectedDocumentIds.length}
      title="Move to selected folder"
      open={showMoveDialog}
      onOpenChange={setShowMoveDialog}
      multiSelect={false}
      onlyFolders={true}
      showRoot={true}
      onSubmit={async (selectedNodes) => {
        setIsFetching(true);

        try {
          const selectedNode = selectedNodes.pop();
          if (!selectedNode) {
            throw new Error('No folder selected');
          }

          const node = tree.entries.get(selectedNode.id);
          const selectedId = node?.folder?.id ?? null;
          if (!selectedId && selectedNode.id !== TREE_ROOT_ID) {
            throw new Error('No folder selected');
          }

          for (const selectedDocumentId of selectedDocumentIds) {
            const payload: MoveDocumentPayload = {
              documentId: selectedDocumentId,
              newParentFolderId: selectedId,
            };
            await fetchEndpointData('/api/v1/workspace/document/move', {
              method: 'POST',
              body: payload,
            });
          }

          for (const selectedFolderId of selectedFolderIds) {
            const payload: MoveFolderPayload = {
              workspaceId: workspace.id,
              folderId: selectedFolderId,
              newParentFolderId: selectedId,
            };
            await fetchEndpointData<MoveFolderPayload>(`/api/v1/workspace/folder/move`, {
              method: 'POST',
              body: payload,
            });
          }

          onComplete();
          setShowMoveDialog(false);

          toast.success('Moved items to the new folder successfully');
        } catch (err) {
          captureException(err);
          console.error(err);

          toast.error(`Failed to move the items to the new folder: ${getDisplayError(err)}`);
        }

        setIsFetching(false);
      }}
    />
  );
};

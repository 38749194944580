import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { useTeam } from '@/app/team/context/TeamContext';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';

export interface IFullPageDropUploadProps {
  folderId: string | null;
  allowedTypes: string[];
}

export const FullPageDropUpload: React.FC<IFullPageDropUploadProps> = (props) => {
  const { folderId, allowedTypes } = props;
  const { team } = useTeam();
  const { syncState } = useWorkspace();

  const [isDroppingFiles, setIsDroppingFiles] = useState(false);
  const dragCountRef = useRef(0);
  useEffect(() => {
    const allowedTypesSet = new Set(allowedTypes);
    const updateDragCounter = (diff: number) => {
      dragCountRef.current = dragCountRef.current + diff;
    };

    const handleWindowDragEnter = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(1);

      if (evt.dataTransfer && evt.dataTransfer.items && evt.dataTransfer.items.length > 0) {
        setIsDroppingFiles(true);
      }
    };

    const handleWindowDragLeave = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(-1);

      if (dragCountRef.current === 0) {
        setIsDroppingFiles(false);
      }
    };

    const handleWindowDragOver = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
    };

    const handleWindowDrop = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsDroppingFiles(false);

      if (evt.dataTransfer) {
        const droppedFiles = [...evt.dataTransfer.files];
        if (droppedFiles?.length > 0) {
          for (const file of droppedFiles) {
            if (!allowedTypesSet.has(file.type)) {
              toast.error(`File type ${file.type} is not allowed`);
              continue;
            }

            const mbSize = file.size / 1024 / 1024;
            if (mbSize > team.uploadLimit) {
              toast.error(`File size ${mbSize}MB exceeds the upload limit ${team.uploadLimit}MB`);
              continue;
            }

            syncState.startUpload({
              folderId,
              file,
            });
          }
        }
      }
    };

    // Add window event listeners
    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragleave', handleWindowDragLeave);
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('drop', handleWindowDrop);

    // Cleanup
    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragleave', handleWindowDragLeave);
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, [folderId, allowedTypes]);

  if (!isDroppingFiles) {
    return null;
  }

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 z-dialog p-2"
      style={{
        backgroundColor: 'rgba(100, 132, 234, 0.2)',
      }}
    >
      <div className="border-2 border-dashed border-blue-400 rounded-lg w-full h-full flex justify-center items-center">
        <div className="text-xl">Drop Files to upload</div>
      </div>
    </div>
  );
};

import { classNames } from '../utils/classnames';

export interface IStatusDotProps {
  color: 'green' | 'yellow' | 'red' | 'blue';
  size: 3 | 6 | 8;
  pulse?: boolean;
}

export const StatusDot: React.FC<IStatusDotProps> = (props) => {
  const { color, size, pulse } = props;

  return (
    <div
      className={classNames('rounded-full', {
        'bg-green-500': color === 'green',
        'bg-yellow-500': color === 'yellow',
        'bg-danger-color': color === 'red',
        'bg-blue-500': color === 'blue',
        'w-3 h-3': size === 3,
        'w-6 h-6': size === 6,
        'w-8 h-8': size === 8,
        'animate-pulse': pulse,
      })}
    ></div>
  );
};

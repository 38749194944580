import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { PermissionCheck } from '@/app/user/components/PermissionCheck';

export interface IWorkspacePermissionsSelectorProps {
  value: WorkspacePermissionEnum[];
  onChange: (value: WorkspacePermissionEnum[]) => void;
}

export const WorkspacePermissionsSelector: React.FC<IWorkspacePermissionsSelectorProps> = (props) => {
  const { value, onChange } = props;

  const isChecked = (permission: WorkspacePermissionEnum) => value.includes(permission);

  const handleChange = (permission: WorkspacePermissionEnum) => {
    return () => {
      const hasValue = value.includes(permission);
      if (hasValue) {
        onChange(value.filter((p) => p !== permission));
      } else {
        onChange([...value, permission]);
      }
    };
  };

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.WriteDocuments)}
        onChange={handleChange(WorkspacePermissionEnum.WriteDocuments)}
        title="Write documents"
        description="Allow user to write documents"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.DeleteDocuments)}
        onChange={handleChange(WorkspacePermissionEnum.DeleteDocuments)}
        title="Delete Documents"
        description="Allow user to delete documents"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.Summarize)}
        onChange={handleChange(WorkspacePermissionEnum.Summarize)}
        title="Summarize"
        description="Allow user to summarize documents"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.Chat)}
        onChange={handleChange(WorkspacePermissionEnum.Chat)}
        title="Chat"
        description="Allow user to chat with the documents"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.RunPresets)}
        onChange={handleChange(WorkspacePermissionEnum.RunPresets)}
        title="Run Presets"
        description="Allow user to run presets"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.DeletePresetResults)}
        onChange={handleChange(WorkspacePermissionEnum.DeletePresetResults)}
        title="Delete Preset Results"
        description="Allow user to delete preset run results"
      />
      <PermissionCheck
        isChecked={isChecked(WorkspacePermissionEnum.ManageMembers)}
        onChange={handleChange(WorkspacePermissionEnum.ManageMembers)}
        title="Manage Members"
        description="Allow user to manage members"
      />
    </div>
  );
};

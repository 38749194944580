import toast from 'react-hot-toast';
import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import useSWR from 'swr';
import { Warning as WarningIcon } from '@phosphor-icons/react';

import { FormDialog } from '@/components/dialog/FormDialog';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { useTeam } from '@/app/team/context/TeamContext';
import { SimpleSelectField } from '@/components/select/SimpleSelectField';
import { getDisplayError } from '@/utils/get-display-error';
import { ISimpleSelectItem } from '@/components/select/SimpleSelect';
import { nullthrows } from '@/utils/invariant';
import { QuestionsPresetType } from '@/app/questionsPreset/enums';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as QuestionsPresetsResponseType } from '@/app/questionsPreset/endpoints/QuestionsPresetsEndpoint';
import type { BodyType as StartPresetRunPayload } from '../endpoints/StartPresetRunEndpoint';
import { InputField } from '@/components/input/InputField';
import { DocumentPickerDialogWithTrigger } from '@/app/workspaceDocument/components/DocumentPicker';
import classNames from '@/utils/classnames';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { TREE_ROOT_ID, TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { captureException } from '@sentry/react';
import { LanguageSelectField } from '@/components/LanguageSelect';
import { CategoryMultiSelectField } from '@/app/category/components/CategoryMultiSelect';

const DOC_ALERT_TRESHOLD = 50;

interface IValues {
  preset?: (ISimpleSelectItem & { type: string }) | null;
  companyName?: string | null;
  selectedFolder?: TreeNode | null;
  language: string;
  categories: string[];
}

const ConditionalFields = () => {
  const { values } = useFormikContext<IValues>();

  const preset = values.preset;
  if (!preset || preset.type !== QuestionsPresetType.Company) {
    return null;
  }

  return <InputField name="companyName" labelText="Company Name" />;
};

interface ISubFieldsProps {
  rootExplorerId: string;
}

const SubFields: React.FC<ISubFieldsProps> = (props) => {
  const { rootExplorerId } = props;
  const { tree } = useWorkspace();
  const { values, isSubmitting, setFieldValue } = useFormikContext<IValues>();
  const [showFolderPicket, setShowFolderPicker] = useState(false);

  const selectedFolder = values.selectedFolder;
  const categories = values.categories;
  const docCount = useMemo(() => {
    const categoriesSet = new Set<string>(categories);
    const ids = tree.getDocumentIdsFromFoldersAndDocuments([selectedFolder?.id ?? null], [], true);
    const filteredIds = categories.length
      ? new Set(
          [...ids].filter((id) => {
            const doc = tree.entries.get(id);
            const docCategories = doc?.document?.categories ?? [];
            for (const c of docCategories) {
              if (categoriesSet.has(c.id)) {
                return true;
              }
            }
            return false;
          }),
        )
      : ids;
    return filteredIds.size;
  }, [selectedFolder, tree, categories]);

  return (
    <div>
      <DocumentPickerDialogWithTrigger
        showRoot={true}
        rootExplorerId={rootExplorerId}
        triggerText={selectedFolder ? `Selection: ${selectedFolder.name}` : 'Select a folder'}
        isDisabled={isSubmitting}
        title="Select a folder"
        open={showFolderPicket}
        onOpenChange={setShowFolderPicker}
        multiSelect={false}
        onlyFolders={true}
        onSubmit={(selectedNodes) => {
          const selectedNode = selectedNodes.pop() ?? null;
          const selectedId = selectedNode?.folder?.id ?? null;
          if (!selectedId) {
            setFieldValue('selectedFolder', null);
            setShowFolderPicker(false);
            return;
          }

          setFieldValue('selectedFolder', selectedNode);
          setShowFolderPicker(false);
        }}
      />

      <div className="flex justify-end mt-4 mb-2 gap-1">
        {docCount === 0 ? (
          <div className="text-danger-color">No documents selected</div>
        ) : (
          <div
            className={classNames('flex gap-1 items-center', {
              'text-danger-color': docCount > DOC_ALERT_TRESHOLD,
            })}
          >
            {docCount > DOC_ALERT_TRESHOLD && <WarningIcon className="w-4 h-4" />}
            <div>
              This preset will be executed on:<span className="font-medium ml-1">{docCount} documents</span>
            </div>
          </div>
        )}

        <InfoDialog title="Hint: preset document count">
          <div>
            Presets always run over all selected documents, the more documents you select, the more expensive the preset
            run becomes. Make sure to always select the most minimal subset of data to ensure quick and efficient preset
            runs and to prevent excessive costs.
          </div>
        </InfoDialog>
      </div>
    </div>
  );
};

export interface IStartPresetRunDialogProps {
  isProcessing?: boolean;
  onStart?: () => void;
}

export const StartPresetRunDialog: React.FC<IStartPresetRunDialogProps> = (props) => {
  const { isProcessing, onStart } = props;
  const { team } = useTeam();
  const { workspace, tree } = useWorkspace();
  const { data, isLoading } = useSWR<QuestionsPresetsResponseType>(
    `/api/v1/questions-preset/list?teamId=${team.id}&take=100`,
    fetchEndpointData,
  );

  const presets = data?.presets || [];
  const items = presets.map((p) => {
    return {
      key: p.id,
      name: p.name,
      type: p.type,
    };
  });

  const rootFolderExplorerId = TREE_ROOT_ID;
  if (!rootFolderExplorerId) {
    return null;
  }

  const rootFolderNode = tree.entries.get(rootFolderExplorerId);
  return (
    <FormDialog
      triggerText="Start Preset Run"
      triggerVariant="primary"
      title="Start Preset Run"
      submitText="Start"
      isDisabled={isProcessing}
      isLoading={isLoading && !presets.length}
      onSubmit={async (values) => {
        try {
          const presetId = nullthrows(values.preset?.key as string, 'No preset selected');
          const companyName = values.companyName?.trim() || null;
          const folderId = values.selectedFolder?.folder?.id ?? null;

          const docCount = tree.getDocumentIdsFromFoldersAndDocuments([folderId ?? null], [], true).size;
          if (docCount === 0) {
            throw new Error('No documents selected for preset');
          }

          if (values.preset?.type === QuestionsPresetType.Company && !companyName) {
            throw new Error('Select a company to run a company preset run');
          }

          const payload: StartPresetRunPayload = {
            presetId,
            companyName,
            workspaceId: workspace.id,
            folderIds: [folderId],
            documentIds: [],
            categories: values.categories,
            language: values.language,
          };
          await fetchEndpointData(`/api/v1/workspace/start-preset-run`, {
            method: 'POST',
            body: payload,
          });
          onStart?.();
          toast.success('Preset run has been started');
        } catch (err) {
          captureException(err);
          toast.error('Could not start preset run: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={
        {
          preset: null,
          companyName: null,
          selectedFolder: rootFolderNode,
          language: team.language,
          categories: [],
        } as IValues
      }
    >
      <SimpleSelectField name="preset" labelText="Preset" items={items} />

      <LanguageSelectField name="language" labelText="Language" />

      <CategoryMultiSelectField name="categories" labelText="Categories" />

      <ConditionalFields />

      <SubFields rootExplorerId={rootFolderExplorerId} />
    </FormDialog>
  );
};

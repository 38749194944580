import React, { useId } from 'react';

export interface IPermissionCheckProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  title: string;
  description: string;
}

export const PermissionCheck: React.FC<IPermissionCheckProps> = (props) => {
  const { isChecked, onChange, title, description } = props;
  const id = useId();

  return (
    <label htmlFor={id}>
      <div className="flex gap-2">
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            onChange(!isChecked);
          }}
        />
        <div>{title}</div>
      </div>
      <div className="text-sm">{description}</div>
    </label>
  );
};

import { useEffect, useMemo, useState } from 'react';
import {
  Bug as BugIcon,
  ArrowSquareOut as ExternalLinkIcon,
  User as UserIcon,
  TextColumns as TextColumnsIcon,
  Building as BuildingIcon,
} from '@phosphor-icons/react';
import useSWR from 'swr';

import { Button, LinkButton } from '@/components/button/Button';
import { useAuth } from '@/contexts/auth-context';
import classNames from '@/utils/classnames';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '@/utils/get-display-error';
import { getHighlightedText } from './highlight-match';
import type { ResponseType as DocumentMatchesResponseType } from '../../endpoints/WorkspacePresetRunDocumentMatchesEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { LabeledCheckbox } from '@/components/checkbox/LabeledCheckbox';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Tag } from '@/components/Tag';
import { InfoDialog } from '@/components/dialog/InfoDialog';

interface IReferenceDialogContentProps {
  documentAnswer: string;
  documentAnswerId: string;
  setSearchParams: (newParams: string) => void;
  showDebugInfo: boolean;
}

const ReferenceDialogContent: React.FC<IReferenceDialogContentProps> = (props) => {
  const { documentAnswer, documentAnswerId, setSearchParams, showDebugInfo } = props;
  const { data, isLoading, error } = useSWR<DocumentMatchesResponseType>(
    `/api/v1/workspace/preset-run-document-matches/${documentAnswerId}`,
    fetchEndpointData,
  );

  const matches = useMemo(() => {
    return data?.documentMatches ?? [];
  }, [data]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    const mostRelevant = [...matches].sort(
      (a, b) => (b.answerSimilarity ?? b.questionSimilarity) - (a.answerSimilarity ?? a.questionSimilarity),
    )[0];
    if (mostRelevant) {
      if (mostRelevant.documentChunk.page) {
        searchParams.set('pageNumber', mostRelevant.documentChunk.page.toString());
      }

      if (mostRelevant.documentChunk.pageRef) {
        searchParams.set('pageRef', mostRelevant.documentChunk.pageRef);
      }
    }
    setSearchParams(searchParams.toString());
  }, [matches]);

  const topSimilarity = useMemo(() => {
    return Math.max(
      ...matches.map((m) => {
        return m.answerSimilarity ?? 0;
      }),
    );
  }, [matches]);
  const similarityTreshold = Math.max(topSimilarity - 0.1, 0.35);

  useEffect(() => {
    if (similarityTreshold < 0.1) {
      return;
    }

    const topChunk = matches.find((v) => (v.answerSimilarity ?? 0) >= similarityTreshold);
    if (topChunk) {
      setTimeout(() => {
        const element = window.document.getElementById(`dialog-chunk-${topChunk.id}`);
        if (element) {
          element.scrollIntoView();
        }
      }, 50);
    }
  }, [matches]);

  if (!matches.length) {
    if (isLoading) {
      return <div className="text-gray-400">Loading...</div>;
    }

    if (error) {
      return <div className="text-danger-color">Error loading data: {getDisplayError(error)}</div>;
    }

    return <div>No relevant data found in this document.</div>;
  }

  return (
    <div
      className={classNames('flex flex-col', {
        'gap-2': showDebugInfo,
      })}
    >
      {matches
        .sort((a, b) => a.documentChunk.chunkIdx - b.documentChunk.chunkIdx)
        .map((m) => {
          const highlightPieces = getHighlightedText(m.documentChunk.content, documentAnswer);
          const highlightChunk = m.answerSimilarity && m.answerSimilarity > 0 && m.answerSimilarity === topSimilarity;
          return (
            <div
              key={m.id}
              id={`dialog-chunk-${m.id}`}
              className={classNames('whitespace-pre-line p-2', {
                'bg-gray-100': !highlightChunk && showDebugInfo,
                'bg-blue-50': highlightChunk,
              })}
            >
              {showDebugInfo && (
                <div className="font-medium text-xs text-gray-600 mb-1">
                  {`Chunk ${m.documentChunk.chunkIdx}, page ${m.documentChunk.page || '-'}`}
                </div>
              )}
              <div>
                {highlightPieces.map((v, idx) => {
                  return (
                    <div key={`${m.id}-${idx}`}>
                      <span
                        className={classNames({
                          'bg-yellow-300': v.isHighlighted,
                        })}
                      >
                        {v.value}
                      </span>
                    </div>
                  );
                })}
              </div>
              {showDebugInfo && (
                <div className="flex gap-1 font-medium text-xs text-gray-600 mt-1">
                  <BugIcon className="w-4 h-4" />
                  <div>{`Original match, question similarity: ${Math.round(m.questionSimilarity * 100)}%, answer similarity: ${Math.round((m.answerSimilarity ?? 0) * 100)}%`}</div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export interface IReferenceDialogProps {
  documentAnswer: string;
  documentAnswerId: string;
  document: {
    id: string;
    name: string;
    folderId: string;
  };
  isOpen: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const ReferenceDialog: React.FC<IReferenceDialogProps> = (props) => {
  const { documentAnswer, documentAnswerId, document, isOpen, onOpenChange } = props;
  const [searchParams, setSearchParams] = useState('');
  const { me } = useAuth();
  const { team } = useTeam();
  const { tree, workspace } = useWorkspace();
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [shownDialog, setShownDialog] = useState<'parties' | 'table-of-contents' | null>(null);
  
  const node = tree.getDocumentNode(document.id);
  const nodeDocument = node?.document;

  if (!isOpen) {
    return null;
  }

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className="fixed h-screen top-0 left-0 bg-white z-dialog flex flex-col"
        style={{
          width: '45vw',
        }}
      >
        <div className="px-2 py-4">
          <h1 className="heading-three">{document.name}</h1>

          <div className="grid grid-cols-2 mt-2">
            <div>
              {!!nodeDocument && (
                <div className="flex gap-2">
                  {nodeDocument.parties.length > 0 && (
                    <Tag
                      color="blue"
                      onClick={() => {
                        setShownDialog('parties');
                      }}
                    >
                      <UserIcon className="w-4 h-4 mr-1" />
                      {`${nodeDocument.parties.length}`}
                    </Tag>
                  )}
                  {nodeDocument.tableOfContents.length > 0 && (
                    <Tag
                      color="blue"
                      onClick={() => {
                        setShownDialog('table-of-contents');
                      }}
                    >
                      <TextColumnsIcon className="w-4 h-4 mr-1" />
                      {`${nodeDocument.tableOfContents.length}`}
                    </Tag>
                  )}

                  <InfoDialog
                    title="Parties"
                    hideTrigger={true}
                    isOpen={shownDialog === 'parties'}
                    setIsOpen={(newOpen) => {
                      if (!newOpen) {
                        setShownDialog(null);
                      } else {
                        setShownDialog('parties');
                      }
                    }}
                  >
                    <div className="flex flex-col">
                      {nodeDocument.parties.map((party) => {
                        return (
                          <div key={party.id} className="flex items-center gap-2">
                            {party.isPerson ? <UserIcon className="w-4 h-4" /> : <BuildingIcon className="w-4 h-4" />}
                            <div>{party.name}</div>
                            <div className="flex flex-wrap gap-2">
                              {party.isPerson && party.companyName && <Tag color="orange">{party.companyName}</Tag>}

                              {party.roles.map((role, roleIdx) => {
                                return (
                                  <Tag color="blue" key={`${party.id}-role-${roleIdx}`}>
                                    {role}
                                  </Tag>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </InfoDialog>

                  <InfoDialog
                    title="Table of Contents"
                    hideTrigger={true}
                    isOpen={shownDialog === 'table-of-contents'}
                    setIsOpen={(newOpen) => {
                      if (!newOpen) {
                        setShownDialog(null);
                      } else {
                        setShownDialog('table-of-contents');
                      }
                    }}
                  >
                    <div className="flex flex-col">
                      {nodeDocument.tableOfContents.map((line, idx) => {
                        return <div key={idx}>{line}</div>;
                      })}
                    </div>
                  </InfoDialog>
                </div>
              )}
            </div>

            <div className="flex items-center">
              {me.isSuperUser && (
                <div>
                  <LabeledCheckbox
                    labelText="Debug view"
                    isChecked={showDebugInfo}
                    onChange={setShowDebugInfo}
                    key={Math.random()}
                    tabIndex={-1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-y-auto">
          <ReferenceDialogContent
            documentAnswer={documentAnswer}
            documentAnswerId={documentAnswerId}
            setSearchParams={setSearchParams}
            showDebugInfo={showDebugInfo}
          />
        </div>

        <div className="flex justify-between flex-shrink-0 p-2">
          <LinkButton
            variant="primary"
            to={`/app/t/${team.id}/workspace/${workspace.id}/documents/${document.folderId}/${document.id}?${searchParams}`}
            iconLeft={<ExternalLinkIcon className="button-icon" />}
          >
            Open Document
          </LinkButton>

          <Button
            onTrigger={() => {
              onOpenChange(false);
            }}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

import React from 'react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { CategorySelectField } from '../components/CategorySelect';
import { TextAreaField } from '@/components/textarea/TextAreaField';
import { FormikHelpers } from 'formik';

interface ICategoryFormValues {
  name: string;
  description: string;
  parentCategoryId: string | null;
}

interface ICategoryFormProps {
  isOpen?: boolean;
  setIsOpen?: (newIsOpen: boolean) => void;
  title: string;
  submitText: string;
  onSubmit: (value: ICategoryFormValues, helpers: FormikHelpers<ICategoryFormValues>) => Promise<void>;
  excludedCategoryIds?: string[];
  initialValues?: {
    name: string;
    description: string;
    parentCategoryId: string | null;
  };
}

export const CategoryFormDialog: React.FC<ICategoryFormProps> = (props) => {
  const { isOpen, setIsOpen, title, submitText, excludedCategoryIds, initialValues, onSubmit } = props;

  return (
    <FormDialog
      open={isOpen}
      setOpen={setIsOpen}
      showTrigger={false}
      triggerText=""
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
      initialValues={
        initialValues ?? {
          name: '',
          description: '',
          parentCategoryId: null,
        }
      }
    >
      <CategorySelectField
        labelText="Parent category"
        name="parentCategoryId"
        excludedCategoryIds={excludedCategoryIds}
      />

      <InputField type="text" labelText="Name" name="name" />

      <TextAreaField labelText="Description" name="description" />
    </FormDialog>
  );
};

import { UploadSimple as UploadIcon } from '@phosphor-icons/react';
import { useRef } from 'react';

import { Button } from './button/Button';

export interface ImportButtonProps {
  allowMultiple?: boolean;
  accept?: string;
  isDisabled?: boolean;
  children?: React.ReactNode;
  onFileUpload: (files: FileList) => void;
}

export const ImportButton: React.FC<ImportButtonProps> = (props) => {
  const { allowMultiple, accept, isDisabled, children, onFileUpload } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;

    let inputFiles = e.target.files;
    if (!inputFiles) {
      return;
    }

    onFileUpload(inputFiles);

    e.target.value = '';
  };

  return (
    <>
      <input
        type="file"
        multiple={Boolean(allowMultiple)}
        accept={accept}
        onChange={handleFileUpload}
        className="hidden"
        ref={inputRef}
      ></input>

      <Button
        isDisabled={isDisabled}
        iconLeft={<UploadIcon className="button-icon" />}
        onTrigger={() => {
          inputRef.current?.click();
        }}
      >
        {children}
      </Button>
    </>
  );
};

import { Fragment } from 'react';
import { CaretRight as ChevronRightIcon } from '@phosphor-icons/react';

import { LinkButton } from '../button/Button';
import { UserMenu } from '../UserMenu';
import { TeamSelector } from './TeamSelector';
import { Sidebar } from './Sidebar';

export interface ISidebarButton {
  icon: React.ReactNode;
  title: string;
  to: string;
}

export interface IDashboardProps {
  children: React.ReactNode;
  sidebarTopButtons: Array<ISidebarButton>;
  sidebarBottomButtons: Array<ISidebarButton>;
  breadcrumbButtons: Array<React.ReactNode>;
}

export const TeamDashboardTemplate: React.FC<IDashboardProps> = (props) => {
  const { children, breadcrumbButtons, sidebarTopButtons, sidebarBottomButtons } = props;

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar>
        <div className="flex h-full flex-col justify-between">
          <div>
            <TeamSelector />

            <div className="grid mt-4">
              {sidebarTopButtons.map((v, idx) => {
                return (
                  <LinkButton key={idx} variant="ghost" iconLeft={v.icon} to={v.to} title={v.title}>
                    {v.title}
                  </LinkButton>
                );
              })}
            </div>
          </div>

          <div className="grid">
            {sidebarBottomButtons.map((v, idx) => {
              return (
                <LinkButton key={idx} variant="ghost" iconLeft={v.icon} to={v.to} title={v.title}>
                  {v.title}
                </LinkButton>
              );
            })}
          </div>
        </div>
      </Sidebar>

      <div className="w-full">
        <header className="flex justify-between p-2 border-b border-b-gray-300 select-none">
          <div className="flex items-center gap-2">
            {breadcrumbButtons.map((v, idx) => {
              return (
                <Fragment key={idx}>
                  {v}
                  {idx < breadcrumbButtons.length - 1 && (
                    <div>
                      <ChevronRightIcon className="button-icon" />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>

          <UserMenu />
        </header>

        <main>{children}</main>
      </div>
    </div>
  );
};

import { TeamPermissionEnum } from '@/app/auth/enum';
import { PermissionCheck } from '../../user/components/PermissionCheck';

export interface ITeamUserPermissionsSelectorProps {
  value: TeamPermissionEnum[];
  onChange: (value: TeamPermissionEnum[]) => void;
}

export const TeamUserPermissionsSelector: React.FC<ITeamUserPermissionsSelectorProps> = (props) => {
  const { value, onChange } = props;

  const isChecked = (permission: TeamPermissionEnum) => value.includes(permission);

  const handleChange = (permission: TeamPermissionEnum) => {
    return () => {
      const hasValue = value.includes(permission);
      if (hasValue) {
        onChange(value.filter((p) => p !== permission));
      } else {
        onChange([...value, permission]);
      }
    };
  };

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
      <PermissionCheck
        isChecked={isChecked(TeamPermissionEnum.CreateWorkspaces)}
        onChange={handleChange(TeamPermissionEnum.CreateWorkspaces)}
        title="Create workspaces"
        description="Allow user to create workspaces"
      />
      <PermissionCheck
        isChecked={isChecked(TeamPermissionEnum.ManageCategories)}
        onChange={handleChange(TeamPermissionEnum.ManageCategories)}
        title="Manage Categories"
        description="Allow user to manage categories"
      />
      <PermissionCheck
        isChecked={isChecked(TeamPermissionEnum.ManagePresets)}
        onChange={handleChange(TeamPermissionEnum.ManagePresets)}
        title="Create presets"
        description="Allow user to manage presets"
      />
    </div>
  );
};

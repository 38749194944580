import { useState } from 'react';
import { Prohibit as BanIcon, DownloadSimple as DownloadIcon } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { Checkbox } from '@/components/checkbox/Checkbox';
import { Button } from '@/components/button/Button';
import { WorkspacePresetRun } from './types';
import { DataField } from '@/components/DataField';
import { StatusDot } from '@/components/StatusDot';
import { getDisplayError } from '@/utils/get-display-error';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { AggregatedAnswersResults, downloadAnswersAsDocx, useGroupedAnswers } from './AggregatedAnswerResults';
import { PresetRunStatus } from '../../enums';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { BodyType as CancelPresetRunPayload } from '../../endpoints/CancelPresetRunEndpoint';
import { captureException } from '@sentry/react';
import { useCategories } from '@/app/category/contexts/CategoriesContext';

export const CompanyPresetRunPage: React.FC<{ presetRun: WorkspacePresetRun }> = (props) => {
  const { presetRun } = props;
  const { categoryMap } = useCategories();
  const [showMinified, setShowMinified] = useState(false);

  const categoryNames = presetRun.categories.map((v) => categoryMap.get(v)?.name).filter(Boolean);
  const grouped = useGroupedAnswers(presetRun);
  const answerCount = Object.keys(grouped).length;
  const isPending = presetRun.status === PresetRunStatus.Running;
  return (
    <div className="grid gap-4">
      <div className="mb-2 flex justify-between">
        <div className="flex gap-8 items-center">
          <DataField title="Status">
            <div className="flex gap-1 items-center">
              <StatusDot
                size={3}
                color={
                  presetRun.status === PresetRunStatus.Running
                    ? 'blue'
                    : presetRun.status === PresetRunStatus.Canceled
                      ? 'red'
                      : 'green'
                }
                pulse={presetRun.status === PresetRunStatus.Running}
              />
              {presetRun.status}
            </div>
          </DataField>

          <DataField title="Language">{presetRun.language.toUpperCase()}</DataField>

          <DataField title="Company">{presetRun.companyName || 'Unknown'}</DataField>

          {categoryNames.length > 0 && <DataField title="Categories">{categoryNames.join(',')}</DataField>}
        </div>

        <div className="flex gap-2 items-center">
          {presetRun.status === PresetRunStatus.Finished && answerCount > 0 && (
            <>
              <Button
                iconLeft={<DownloadIcon className="button-icon" />}
                onTrigger={() => {
                  downloadAnswersAsDocx(grouped);
                }}
              >
                Word
              </Button>
            </>
          )}

          {isPending && (
            <div>
              <ConfirmDialog
                triggerText={<BanIcon className="button-icon" />}
                title="Cancel"
                submitText="Cancel"
                triggerVariant="destructive"
                description="Are you sure you want to cancel this preset run?"
                onSubmit={async () => {
                  try {
                    const payload: CancelPresetRunPayload = {
                      presetRunId: presetRun.id,
                    };
                    await fetchEndpointData(`/api/v1/workspace/preset-run/cancel`, {
                      method: 'DELETE',
                      body: payload,
                    });
                    toast.success('Preset run canceled');
                  } catch (err) {
                    captureException(err);
                    toast.error('Could not cancel preset run: ' + getDisplayError(err));
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mb-2">
        <div>{presetRun.name}</div>

        <label className="flex items-center gap-2">
          <Checkbox isChecked={showMinified} onChange={setShowMinified} />
          <div>minified</div>
        </label>
      </div>

      <AggregatedAnswersResults presetRun={presetRun} showMinified={showMinified} />
    </div>
  );
};

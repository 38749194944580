import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import classNames from '@/utils/classnames';
import { useMemo, Fragment } from 'react';

export interface IDocumentTreeBreadcrumbProps {
  nodeId: string;
  onSelect: (nodeId: string) => void;
}

export const DocumentTreeBreadcrumb: React.FC<IDocumentTreeBreadcrumbProps> = (props) => {
  const { nodeId, onSelect } = props;
  const { tree, treeKey } = useWorkspace();

  const parentNodes = useMemo(() => {
    const nodes = [];
    let currentNode = tree.getNode(nodeId);
    while (currentNode) {
      nodes.push(currentNode);
      if (!currentNode.parentFolderId) {
        break;
      }
      currentNode = tree.getNode(currentNode.parentFolderId);
    }
    return nodes.reverse();
  }, [tree, treeKey, nodeId]);

  return (
    <div className="flex gap-1">
      {parentNodes.map((v) => {
        return (
          <Fragment key={v.id}>
            <div className="text-gray-500">/</div>
            <div
              className={classNames('hover:text-link-color cursor-pointer', {
                'font-medium': v.id === nodeId,
              })}
              onClick={() => {
                onSelect(v.id);
              }}
            >
              {v.name}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

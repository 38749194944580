import * as React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { nullthrows } from '../../../utils/invariant';
import createContext from '../../../contexts/create-context';
import { getWorkspaceStates, WorkspaceState } from './WorkspaceStates';
import { useTeam } from '@/app/team/context/TeamContext';
import { SpinnerBlock } from '../../../components/Spinner';
import { getDisplayError } from '../../../utils/get-display-error';
import { useThrottle } from '../../../hooks/useThrottle';
import type { ResponseType as WorkspaceResponseType } from '../endpoints/WorkspaceEndpoint';
import type { ResponseType as WorkspaceCompaniesResponseType } from '../endpoints/WorkspaceCompaniesEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import {
  getWorkspaceDocumentTreeState,
  TreeNode,
  WorkspaceDocumentTree,
} from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';

export type Workspace = WorkspaceResponseType['workspace'];

interface ProviderValue {
  workspace: Workspace;
  syncState: WorkspaceState;
  companies: WorkspaceCompaniesResponseType['companies'];
  isFetching: boolean;
  isSyncingTree: boolean;
  tree: WorkspaceDocumentTree;
  treeKey: number;
  documents: TreeNode[];
  processingState: {
    processedCount: number;
    totalCount: number;
  };
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface WorkspaceProviderProps {
  children?: React.ReactNode;
}

const _WorkspaceProvider: React.FC<{
  workspace: Workspace;
  children?: React.ReactNode;
}> = (props) => {
  const { workspace, children } = props;
  const { team } = useTeam();
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');

  const syncState = getWorkspaceStates().getState(workspaceId, team.id);
  const documentState = getWorkspaceDocumentTreeState(team.id, workspaceId);

  const [treeKey, setTreeKey] = React.useState(0);
  const [isSyncing, setIsSyncing] = React.useState(documentState.isSyncing);

  React.useEffect(() => {
    const disposable = documentState.onTreeChange(() => {
      setTreeKey((prev) => prev + 1);
    });
    const disposableTwo = documentState.onSyncStateChange((_newIsSyncing) => {
      setIsSyncing(_newIsSyncing);
    });
    return () => {
      disposable.dispose();
      disposableTwo.dispose();
    };
  }, [documentState]);

  const documents = React.useMemo(() => {
    return [...documentState.entries.values()].filter((v) => !!v.document);
  }, [documentState, treeKey]);

  const processingState = React.useMemo(() => {
    let processedCount = 0;
    for (const doc of documents) {
      if (doc.document?.hasExtractedMetadata && doc.document?.hasExtractedParties) {
        processedCount += 1;
      }
    }
    return {
      processedCount,
      totalCount: documents.length,
    };
  }, [documents]);

  React.useEffect(() => {
    if (processingState.processedCount === processingState.totalCount) {
      fetchEndpointData(`/api/v1/workspace/extract-companies`, {
        method: 'POST',
        body: {
          workspaceId,
        },
      })
        .then(() => {
          console.log('Document preprocessing started...');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [`${processingState.processedCount}::${processingState.totalCount}`]);

  const {
    data: companiesData,
    isLoading: fetchingCompanies,
    mutate: refetchCompaniesData,
  } = useSWR<WorkspaceCompaniesResponseType>(`/api/v1/workspace/companies/${workspaceId}`, fetchEndpointData);

  const [companiesFetchKey, setCompaniesFetchKey] = React.useState(syncState.companiesVersionHash);
  React.useEffect(() => {
    const changeDisposable = syncState.onCompaniesUpdate((newHash) => {
      setCompaniesFetchKey(newHash);
    });
    return () => {
      changeDisposable.dispose();
    };
  }, [syncState]);

  useThrottle(refetchCompaniesData, companiesFetchKey, 1000);

  const companies = companiesData?.companies ?? [];
  return (
    <ReactProvider
      value={{
        workspace,
        companies,
        syncState,
        isFetching: isSyncing || fetchingCompanies,

        tree: documentState,
        treeKey: treeKey,
        isSyncingTree: isSyncing,
        documents,
        processingState: {
          processedCount: 0,
          totalCount: 0,
        },
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = (props) => {
  const { children } = props;
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');
  const { data, isLoading, error } = useSWR<WorkspaceResponseType>(
    `/api/v1/workspace/get/${workspaceId}`,
    fetchEndpointData,
  );

  const workspace = data?.workspace;

  if (!workspace && isLoading) {
    return <SpinnerBlock message="Loading workspace..." />;
  }

  if (!workspace && error) {
    return <div>{getDisplayError(error)}</div>;
  }

  if (!workspace) {
    return <div>Workspace not found</div>;
  }

  return <_WorkspaceProvider workspace={workspace}>{children}</_WorkspaceProvider>;
};

export const useWorkspace = useContext;
export const WorkspaceConsumer = ReactConsumer;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTeam } from '@/app/team/context/TeamContext';
import { useAuth } from '@/contexts/auth-context';
import { DialogContent, DialogRoot, DialogTrigger } from '../dialog/Dialog';
import { BaseButton } from '../button/BaseButton';
import { LinkButton } from '../button/Button';

const getShortTeamName = (name: string) => {
  if (name.length > 16) {
    return `${name.substring(0, 16)}...`;
  } else {
    return name;
  }
};

const TeamSelectorDialog = () => {
  const { myTeams } = useAuth();
  const navigate = useNavigate();
  const { team: selectedTeam } = useTeam();
  const [open, setOpen] = useState(false);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton variant="ghost" onClick={() => setOpen(true)}>
          {getShortTeamName(selectedTeam.name)}
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Select a team to switch</h1>

        <div className="grid gap-2 overflow-y-auto" style={{ maxHeight: '50vh' }}>
          {myTeams.map((t) => {
            return (
              <button
                key={`${t.team.id}`}
                className="bg-gray-200 hover:bg-gray-300 rounded p-1 cursor-pointer text-left"
                onClick={() => {
                  navigate(`/app/t/${t.team.id}`);
                  setOpen(false);
                }}
              >
                {t.team.name}
              </button>
            );
          })}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export const TeamSelector = () => {
  const { myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();

  if (myTeams.length > 1) {
    return <TeamSelectorDialog />;
  } else {
    return (
      <LinkButton to={`/app/t/${selectedTeam.id}`} variant="ghost">
        {getShortTeamName(selectedTeam.name)}
      </LinkButton>
    );
  }
};

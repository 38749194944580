import { useField } from 'formik';
import { useEffect, useId } from 'react';

import { LabeledCheckbox } from './LabeledCheckbox';

export interface ICheckboxFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
  hideHelperText?: boolean;
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled, hideHelperText } = props;
  const id = useId();
  const [field, meta, helpers] = useField({ name });

  useEffect(() => {
    helpers.setTouched(true);
  }, []);

  const invalidText = meta.touched ? meta.error : undefined;
  return (
    <div>
      <LabeledCheckbox
        isChecked={field.value}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        id={id}
        isInvalid={Boolean(meta.touched && meta.error)}
        isDisabled={isDisabled}
        labelText={labelText}
      />

      {!hideHelperText && (
        <div className="text-sm pb-1 w-full">
          {invalidText ? (
            <span className="text-danger-color">{invalidText}</span>
          ) : (
            <span className="text-gray-600">{helperText ?? <>&zwnj;</>}</span>
          )}
        </div>
      )}
    </div>
  );
};

import {
  Gear as SettingsIcon,
  Briefcase as BriefcaseIcon,
  Sliders as SlidersIcon,
  Tag as TagIcon,
  Coins as CoinsIcon,
  SquaresFour as Grid2X2Icon,
} from '@phosphor-icons/react';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../../contexts/auth-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { ISidebarButton, TeamDashboardTemplate } from './TeamDashboardTemplate';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const TeamDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me, myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();

  const currentTeam = myTeams.find((t) => t.team.id === selectedTeam?.id);

  const sidebarTopButtons = useMemo(() => {
    const buttons: ISidebarButton[] = [
      {
        icon: <Grid2X2Icon className="button-icon" />,
        title: 'Workspaces',
        to: `/app/t/${selectedTeam.id}/workspace`,
      },
      {
        icon: <SlidersIcon className="button-icon" />,
        title: 'Presets',
        to: `/app/t/${selectedTeam.id}/questions-preset`,
      },
      {
        icon: <TagIcon className="button-icon" />,
        title: 'Categories',
        to: `/app/t/${selectedTeam.id}/categories`,
      },
    ];

    return buttons;
  }, [currentTeam, me.isSuperUser]);

  const sidebarBottomButtons = useMemo(() => {
    const buttons: ISidebarButton[] = [
      {
        icon: <CoinsIcon className="button-icon" />,
        title: 'Usage',
        to: `/app/t/${selectedTeam.id}/usage`,
      },
    ];

    if (currentTeam?.isOwner) {
      buttons.push({
        icon: <SettingsIcon className="button-icon" />,
        title: 'Settings',
        to: `/app/t/${selectedTeam.id}/settings`,
      });
    }

    if (me.isSuperUser) {
      buttons.push({
        icon: <BriefcaseIcon className="button-icon" />,
        title: 'Backoffice',
        to: '/app/internal',
      });
    }

    return buttons;
  }, [currentTeam, me.isSuperUser]);

  return (
    <TeamDashboardTemplate
      breadcrumbButtons={[]}
      sidebarTopButtons={sidebarTopButtons}
      sidebarBottomButtons={sidebarBottomButtons}
    >
      {children}
    </TeamDashboardTemplate>
  );
};

export const TeamDashboardWrapper = () => {
  return (
    <TeamDashboard>
      <Outlet />
    </TeamDashboard>
  );
};

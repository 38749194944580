import React from 'react';
import { BaseInput, BaseInputProps } from './BaseInput';

export interface InputProps {
  id?: string;
  value: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: () => void;
  onChange: (newValue: string) => void;
  type: 'text' | 'email' | 'password' | 'number' | 'date' | 'time';
  step?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  size?: BaseInputProps['size'];
  variant?: BaseInputProps['variant'];
  onKeyDown?: BaseInputProps['onKeyDown'];
}

export const Input: React.FC<InputProps> = (props) => {
  const { id, value, onBlur, onFocus, isInvalid, isDisabled, placeholder, step, autoComplete, onKeyDown } = props;
  let type = props.type;
  let onChange = props.onChange;
  if (type === 'number') {
    type = 'text';
    onChange = (newValue) => {
      let cleanedValue = newValue.replace(/[^0-9,.]+/g, '').replace(/,+/g, '.');
      if (cleanedValue.length > 1 && cleanedValue[0] === '0') {
        cleanedValue = cleanedValue.substring(1);
      }
      props.onChange(cleanedValue);
    };
  }

  return (
    <BaseInput
      id={id}
      variant={props.variant}
      size={props.size}
      type={type}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      onChange={(evt) => onChange(evt.target.value)}
      disabled={isDisabled}
      step={step}
      autoComplete={autoComplete}
      onKeyDown={onKeyDown}
    />
  );
};

import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AuthProvider } from './contexts/auth-context';
import { WebsocketProvider } from './contexts/websocket-context';

import { LoginPage } from './app/auth/pages/Login';
import { CreateTeamPage } from './app/team/pages/CreateTeam';
import { TeamsPage } from './app/team/pages/Teams';
import { TeamWrapper } from './app/team/pages/TeamWrapper';
import { WorkspaceChatPage } from './app/workspaceChat/pages/WorkspaceChatPage';
import { TeamUsersPage } from './app/teamUser/pages/TeamUsers';
import { AddTeamUserPage } from './app/teamUser/pages/AddTeamUser';
import { SettingsPage } from './app/settings/pages/SettingsPage';
import { WorkspaceChatHistoryPage } from './app/workspaceChat/pages/WorkspaceChatHistory';
import { UserNotFoundPage } from './app/auth/pages/UserNotFound';
import { TeamSettingsPage } from './app/team/pages/TeamSettings';
import { MyProfilePage } from './app/user/pages/MyProfile';
import { UsagePage } from './app/usage/pages/Usage';
import { InternalTeamsPage } from './app/team/pages/InternalTeamsPage';
import { InternalTeamPage } from './app/team/pages/InternalTeamPage';
import { UserDashboard } from './components/page-templates/UserDashboard';
import { WorkspacesPage } from './app/workspace/pages/WorkspacesPage';
import { CreateWorkspacePage } from './app/workspace/pages/CreateWorkspacePage';
import { WorkspacePage } from './app/workspace/pages/workspace/WorkspacePage';
import { WorkspaceDocumentsPage } from './app/workspaceDocument/pages/WorkspaceDocumentsPage';
import { WorkspaceCompaniesPage } from './app/workspace/pages/workspace/WorkspaceCompaniesPage';
import { PresetsPage } from './app/questionsPreset/pages/PresetsPage';
import { CreatePresetPage } from './app/questionsPreset/pages/CreatePresetPage';
import { UpdatePresetPage } from './app/questionsPreset/pages/UpdatePresetPage';
import { WorkspacePresetRunsPage } from './app/workspacePresetRun/pages/WorkspacePresetRunsPage';
import { PresetRunPage } from './app/workspacePresetRun/pages/preset-run/WorkspacePresetRunPage';
import { ApiKeysPage } from './app/apiKey/pages/ApiKeysPage';
import { CreateApiKeyPage } from './app/apiKey/pages/CreateApiKeyPage';
import { CategoriesPage } from './app/category/pages/CategoriesPage';
import { ChatRedirectComponent } from './app/workspaceChat/pages/ChatRedirect';
import { WorkspaceOverviewPage } from './app/workspace/pages/workspace/WorkspaceOverviewPage';
import { LoginFailedPage } from './app/auth/pages/LoginFailed';
import { TREE_ROOT_ID } from './app/workspaceDocument/tree/WorkspaceDocumentTree';
import { WorkspaceDocumentPage } from './app/workspaceDocument/pages/WorkspaceDocumentPage';
import { TeamDashboard, TeamDashboardWrapper } from './components/page-templates/TeamDashboard';
import { WorkspaceSettingsPage } from './app/workspaceSettings/pages/WorkspaceSettingsPage';
import { UpdateTeamUserPage } from './app/teamUser/pages/UpdateTeamUserPage';
import { WorkspaceUsersPage } from './app/workspaceUser/pages/WorkspaceUsersPage';
import { AddWorkspaceUserPage } from './app/workspaceUser/pages/AddWorkspaceUserPage';
import { UpdateWorkspaceUserPage } from './app/workspaceUser/pages/UpdateWorkspaceUserPage';
import { WorkspaceAuditLogsPage } from './app/workspaceAuditLog/pages/WorkspaceAuditLogsPage';
import { WorkspaceDocumentRedirectPage } from './app/workspaceDocument/pages/WorkspaceDocumentRedirectPage';

const AppWrapper = () => {
  return (
    <AuthProvider>
      <WebsocketProvider>
        <Outlet />
      </WebsocketProvider>
    </AuthProvider>
  );
};

const UserDashboardRoute = () => {
  return (
    <UserDashboard>
      <Outlet />
    </UserDashboard>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/app" element={<AppWrapper />}>
      <Route path="me" element={<UserDashboardRoute />}>
        <Route index element={<MyProfilePage />} />
      </Route>

      <Route path="internal" element={<UserDashboardRoute />}>
        <Route path="teams">
          <Route index element={<InternalTeamsPage />} />
          <Route path="new" element={<CreateTeamPage />} />
          <Route path=":internalTeamId" element={<InternalTeamPage />} />
        </Route>

        <Route index element={<Navigate to="teams" />} />
      </Route>

      <Route path="t">
        <Route path=":teamId" element={<TeamWrapper />}>
          <Route index element={<Navigate to="workspace" />} />
          <Route
            path="usage"
            element={
              <TeamDashboard>
                <UsagePage />
              </TeamDashboard>
            }
          />
          <Route
            path="categories"
            element={
              <TeamDashboard>
                <CategoriesPage />
              </TeamDashboard>
            }
          />
          <Route path="settings" element={<TeamDashboardWrapper />}>
            <Route path="team" element={<TeamSettingsPage />} />
            <Route path="users">
              <Route index element={<TeamUsersPage />} />
              <Route path="edit/:userId" element={<UpdateTeamUserPage />} />
              <Route path="add" element={<AddTeamUserPage />} />
            </Route>
            <Route path="api-keys">
              <Route index element={<ApiKeysPage />} />
              <Route path="new" element={<CreateApiKeyPage />} />
            </Route>
            <Route index element={<SettingsPage />} />
          </Route>
          <Route path="questions-preset" element={<TeamDashboardWrapper />}>
            <Route index element={<PresetsPage />} />
            <Route path="new" element={<CreatePresetPage />} />
            <Route path=":presetId" element={<UpdatePresetPage />} />
          </Route>
          <Route path="workspace">
            <Route
              index
              element={
                <TeamDashboard>
                  <WorkspacesPage />
                </TeamDashboard>
              }
            />
            <Route
              path="new"
              element={
                <TeamDashboard>
                  <CreateWorkspacePage />
                </TeamDashboard>
              }
            />
            <Route path=":workspaceId" element={<WorkspacePage />}>
              <Route index element={<Navigate to="overview" />} />
              <Route path="overview" element={<WorkspaceOverviewPage />} />
              <Route path="documents">
                <Route index element={<Navigate to={TREE_ROOT_ID} />} />
                <Route path="redirect-to/:documentId" element={<WorkspaceDocumentRedirectPage />} />
                <Route path=":folderId">
                  <Route index element={<WorkspaceDocumentsPage />} />
                  <Route path=":documentId" element={<WorkspaceDocumentPage />} />
                </Route>
              </Route>
              <Route path="companies" element={<WorkspaceCompaniesPage />} />
              <Route path="chat">
                <Route index element={<ChatRedirectComponent />} />
                <Route path="new" element={<ChatRedirectComponent prefix="../" />} />
                <Route path="history" element={<WorkspaceChatHistoryPage />} />
                <Route path=":chatId" element={<WorkspaceChatPage />} />
              </Route>
              <Route path="questions-preset">
                <Route index element={<PresetsPage />} />
                <Route path="new" element={<CreatePresetPage />} />
                <Route path=":presetId" element={<UpdatePresetPage />} />
              </Route>
              <Route path="preset-runs">
                <Route index element={<WorkspacePresetRunsPage />} />
                <Route path=":presetRunId" element={<PresetRunPage />} />
              </Route>
              <Route path="audit-logs">
                <Route index element={<WorkspaceAuditLogsPage />} />
              </Route>
              <Route path="settings">
                <Route index element={<WorkspaceSettingsPage />} />
                <Route path="users">
                  <Route index element={<WorkspaceUsersPage />} />
                  <Route path="edit/:userId" element={<UpdateWorkspaceUserPage />} />
                  <Route path="add" element={<AddWorkspaceUserPage />} />
                </Route>
              </Route>
              <Route index path="*" element={<Navigate to="." replace={true} />} />
            </Route>
          </Route>
          <Route index path="*" element={<Navigate to="documents" replace={true} />} />
        </Route>
        <Route index path="*" element={<TeamsPage />} />
      </Route>
      <Route index path="*" element={<Navigate to="/app/t" replace={true} />} />
    </Route>,

    <Route path="/login" element={<LoginPage />} />,
    <Route path="/user-not-found" element={<UserNotFoundPage />} />,
    <Route path="/auth-failed" element={<LoginFailedPage />} />,

    <Route index element={<Navigate to="/app/t" />} />,

    // TODO: Add some nicer looking 404 page
    <Route
      path="*"
      element={
        <main className="p-2">
          <p>Page not found</p>
        </main>
      }
    />,
  ]),
);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

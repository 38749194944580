import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import type {
  BodyType as AddUserPayload,
  ResponseType as AddUserResponseType,
} from '../endpoints/AddWorkspaceUserEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { WorkspacePermissionsSelector } from '../components/WorkspacePermissionsSelector';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TeamUserSelectField } from '@/app/teamUser/components/TeamUserSelector';
import { captureException } from '@sentry/react';

const addWorkspaceSchema = Yup.object().shape({
  userId: Yup.string().required('Vereist'),
  permissions: Yup.array(Yup.string()).required('Vereist'),
  isOwner: Yup.boolean().required('Vereist'),
});

export const AddWorkspaceUserPage = () => {
  const navigate = useNavigate();
  const { workspace } = useWorkspace();

  const usersUrl = useResolvedPath('..');
  return (
    <div className="page-content">
      <PageHeader title="Add a user" />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: 'Add a user',
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              userId: null as null | string,
              permissions: [],
              isOwner: false,
            }}
            validationSchema={addWorkspaceSchema}
            onSubmit={async (values) => {
              try {
                const userId = values.userId;
                if (!userId) {
                  throw new Error('userId is required');
                }

                const payload: AddUserPayload = {
                  workspaceId: workspace.id,
                  data: {
                    userId,
                    permissions: values.permissions,
                    isOwner: values.isOwner,
                  },
                };
                await fetchEndpointData<AddUserResponseType>('/api/v1/workspace/user/add', {
                  method: 'POST',
                  body: payload,
                });
                navigate('..');
                toast.success('User has been added to the workspace');
              } catch (err: any) {
                captureException(err);
                toast.error('Could not add user to the workspace: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TeamUserSelectField labelText="User" name="userId" />

                  <div className="heading-two mb-2">Permissions</div>

                  <div className="mb-4">
                    <WorkspacePermissionsSelector
                      value={values['permissions']}
                      onChange={(v) => setFieldValue('permissions', v)}
                    />
                  </div>

                  <CheckboxField labelText="Owner" name="isOwner" />

                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                    Add user to the workspace
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

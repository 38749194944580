import toast from 'react-hot-toast';
import React from 'react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { FormDialog } from '@/components/dialog/FormDialog';
import { InputField } from '@/components/input/InputField';
import { getDisplayError } from '@/utils/get-display-error';
import { fetchEndpointData } from '@/utils/fetch.client';
import type {
  ResponseType as CreateFolderResponseType,
  BodyType as CreateFolderPayload,
} from '../endpoints/CreateWorkspaceFolderEndpoint';
import { TREE_ROOT_ID } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { captureException } from '@sentry/react';

export interface IStartPresetRunDialogProps {
  showDialog?: boolean;
  setShowDialog?: (newShow: boolean) => void;
  parentFolderId?: string;
}

interface IValues {
  name: string;
}

export const CreateFolderDialog: React.FC<IStartPresetRunDialogProps> = (props) => {
  const { showDialog, setShowDialog, parentFolderId } = props;
  const { workspace } = useWorkspace();

  return (
    <FormDialog
      triggerText="Create Folder"
      showTrigger={false}
      title="Create Folder"
      submitText="Create"
      isLoading={false}
      open={showDialog}
      setOpen={setShowDialog}
      onSubmit={async (values, helpers) => {
        try {
          const payload: CreateFolderPayload = {
            workspaceId: workspace.id,
            data: {
              parentFolderId: parentFolderId ? (parentFolderId === TREE_ROOT_ID ? null : parentFolderId) : null,
              name: values.name,
            },
          };
          await fetchEndpointData<CreateFolderResponseType>(`/api/v1/workspace/folder/create`, {
            method: 'POST',
            body: payload,
          });
          helpers.resetForm();
          toast.success('Folder has been created');
        } catch (err) {
          captureException(err);
          toast.error('Could not create folder: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={
        {
          name: '',
        } as IValues
      }
    >
      <InputField name="name" labelText="Folder name" />
    </FormDialog>
  );
};

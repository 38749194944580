import React from 'react';

export interface ISidebarProps {
  children: React.ReactNode;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const { children } = props;

  return (
    <div className="relative min-h-screen w-52 flex-shrink-0">
      <div className="fixed h-screen w-52 p-2 border-r bg-gray-100 border-r-gray-200">{children}</div>
    </div>
  );
};

import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import type { BodyType as AddUserPayload, ResponseType as AddUserResponseType } from '../endpoints/AddTeamUserEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { TeamUserPermissionsSelector } from '../components/UserPermissionsSelector';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { captureException } from '@sentry/react';

const addTeamUserSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  email: Yup.string().email().required('Vereist'),
  permissions: Yup.array(Yup.string()).required('Vereist'),
  isOwner: Yup.boolean().required('Vereist'),
});

export const AddTeamUserPage = () => {
  const navigate = useNavigate();
  const { team } = useTeam();

  const usersUrl = useResolvedPath('..');
  return (
    <div className="page-content">
      <PageHeader title="Add a user" />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: 'Add a user',
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              name: '',
              email: '',
              permissions: [],
              isOwner: false,
              language: team.language,
            }}
            validationSchema={addTeamUserSchema}
            onSubmit={async (values) => {
              try {
                const payload: AddUserPayload = {
                  teamId: team.id,
                  data: {
                    name: values.name,
                    email: values.email,
                    language: values.language,
                    permissions: values.permissions,
                    isOwner: values.isOwner,
                  },
                };
                await fetchEndpointData<AddUserResponseType>('/api/v1/team/user/add', {
                  method: 'POST',
                  body: payload,
                });
                navigate('..');
                toast.success('User has been added to the team');
              } catch (err: any) {
                captureException(err);
                toast.error('Could not add user to the team: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <InputField labelText="Name" type="text" name="name" />

                  <InputField labelText="Email" type="email" name="email" />

                  <LanguageSelectField labelText="Language" name="language" />

                  <div className="heading-two mb-2">Permissions</div>

                  <div className="mb-4">
                    <TeamUserPermissionsSelector
                      value={values['permissions']}
                      onChange={(v) => setFieldValue('permissions', v)}
                    />
                  </div>

                  <CheckboxField labelText="Owner" name="isOwner" />

                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                    Add user to the team
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

import { ErrorCode } from './ErrorCodes';

export class CustomError extends Error {
  code: ErrorCode;

  constructor(msg: string, code: ErrorCode) {
    super(msg);
    this.code = code;
  }
}

export class PermissionDeniedError extends CustomError {
  constructor() {
    super('Permission denied', ErrorCode.PERMISSION_DENIED);
  }
}

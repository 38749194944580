import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { CaretLeft as CaretLeftIcon, CaretRight as CaretRightIcon } from '@phosphor-icons/react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { formatDate } from '../../../utils/date';
import { ITableHeader, Table } from '../../../components/table/Table';
import { Button } from '../../../components/button/Button';
import { SpinnerBlock } from '../../../components/Spinner';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as TeamUsageResponseType } from '../endpoints/TeamUsageEndpoint';

const USAGE_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'day',
    name: 'Day',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'count',
    name: 'Count',
  },
  {
    id: 'username',
    name: 'User',
  },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-dark-200 px-2 py-1 rounded text-white">
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export interface IUsageTableProps {
  month: any;
}

const UsageTable: React.FC<IUsageTableProps> = (props) => {
  const { month } = props;
  const { team } = useTeam();
  const { data, isLoading } = useSWR<TeamUsageResponseType>(
    `/api/v1/usage/team?teamId=${team.id}&startDate=${month.startOf('month').toISOString()}&endDate=${month.endOf('month').toISOString()}`,
    fetchEndpointData,
  );

  const usage = useMemo(() => {
    return data?.usage ?? [];
  }, [data]);
  const totalUsage = usage.reduce((acc, entry) => acc + entry.count, 0);

  const groupedByDay = useMemo(() => {
    const map = new Map<string, number>();
    usage.forEach((entry) => {
      const key = formatDate(entry.day);
      const value = map.get(key) ?? 0;
      map.set(key, value + entry.count);
    });
    return Array.from(map.entries()).map(([day, count]) => ({ day, count: Math.ceil(count / 1000) }));
  }, [usage]);

  const usageRows = useMemo(() => {
    return [
      ...usage.map((v) => {
        return {
          ...v,
          day: formatDate(v.day),
        };
      }),
      {
        day: 'Total',
        count: totalUsage,
        type: '',
        username: '',
      },
    ];
  }, [totalUsage, usage]);

  if (!data?.usage && isLoading) {
    return <SpinnerBlock message="Loading usage..." />;
  }

  return (
    <div>
      <div className="mt-8 mb-4">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={groupedByDay}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" tick={{ fill: '#fefefe' }} />
            <YAxis tick={{ fill: '#fefefe' }} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="count" fill="#a7abf9" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <Table
        idKey="id"
        headers={USAGE_TABLE_HEADERS}
        data={usageRows}
        mapData={(entry) => {
          return [entry.day, entry.type, Math.ceil(entry.count / 1000), entry.username];
        }}
      />
    </div>
  );
};

export const UsagePage = () => {
  const { team, refreshTeam } = useTeam();
  const [month, setMonth] = useState(dayjs());

  useEffect(() => {
    refreshTeam();
  }, [true]);

  const remainingCredits = team.remainingCredits ?? 0;
  return (
    <div className="page-content">
      <PageHeader title="Team Usage" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Team Usage',
            },
          ]}
        />

        <div className="flex items-center gap-4">
          <Button
            onTrigger={() => {
              setMonth((v) => v.subtract(1, 'month'));
            }}
          >
            <CaretLeftIcon />
          </Button>
          <div>{month.format('MMMM YYYY')}</div>
          <Button
            onTrigger={() => {
              setMonth((v) => v.add(1, 'month'));
            }}
            isDisabled={month.startOf('month').isSame(dayjs().startOf('month'))}
          >
            <CaretRightIcon />
          </Button>
        </div>
      </div>

      <UsageTable month={month} />

      <div className="font-medium my-4">{`Remaining credits: ${Math.ceil(remainingCredits / 1000)}`}</div>
    </div>
  );
};

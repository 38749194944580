import { useCallback, useEffect } from 'react';
import { Link, useResolvedPath, useSearchParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination';
import { SpinnerBlock } from '../../../components/Spinner';
import { ITableHeader, Table } from '../../../components/table/Table';
import { formatDateTime } from '../../../utils/date';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as ChatsResponseType } from '../endpoints/WorkspaceChatsEndpoint';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';

const HISTORY_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Title',
  },
  {
    id: 'creator',
    name: 'Creator',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const WorkspaceChatHistoryPage = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspace } = useWorkspace();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const searchParams = new URLSearchParams();
      searchParams.set('teamId', team.id);
      searchParams.set('take', variables.take.toString());
      if (variables.cursor) {
        searchParams.set('id', variables.cursor);
      }

      const result = await fetchEndpointData<ChatsResponseType>(
        `/api/v1/workspace/${workspace.id}/chat/list?${searchParams.toString()}`,
      );
      return result.chats ?? [];
    },
    [team.id],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 40,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
  });

  const pageCursor = page.variables.cursor;
  useEffect(() => {
    if (!pageCursor) return;

    setSearchParams((prev) => {
      prev.set('pageCursor', pageCursor);
      return prev;
    });
  }, [pageCursor]);

  const chatPath = useResolvedPath('..');
  const history = page.data ?? [];
  const title = 'Previous chats';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Chat',
              to: chatPath.pathname,
            },
            {
              name: title,
            },
          ]}
        />
      </div>

      {!!history.length && (
        <Table
          idKey="id"
          headers={HISTORY_TABLE_HEADERS}
          data={history}
          mapData={(entry) => {
            return [
              <Link to={`../${entry.id}`} className="w-full hover:text-link-color">
                {entry.title ?? entry.id}
              </Link>,
              <span className="whitespace-nowrap">{entry.creator.name}</span>,
              <span className="whitespace-nowrap">{formatDateTime(entry.createdAt)}</span>,
            ];
          }}
        />
      )}

      {!history.length && !page.isFetching && (
        <div className="card">
          <div>No previous chats found.</div>
        </div>
      )}

      {!history.length && page.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};

import classNames from '@/utils/classnames';
import { CircleNotch as CircleNotchIcon } from '@phosphor-icons/react';
import * as React from 'react';

import { Delay } from './Delay';

export interface ISpinnerProps {
  className?: string;
  size: 4 | 5 | 6 | 8 | 12 | 24;
}

export const Spinner: React.FC<ISpinnerProps> = (props) => {
  const { className, size } = props;

  return (
    <CircleNotchIcon
      className={classNames(
        'animate-spin',
        {
          'h-4 w-4': size === 4,
          'h-5 w-5': size === 5,
          'h-6 w-6': size === 6,
          'h-8 w-8': size === 8,
          'h-12 w-12': size === 12,
          'h-24 w-24': size === 24,
        },
        className,
      )}
    />
  );
};

export interface ISpinnerBlockProps {
  message: string;
  className?: string;
}

export const SpinnerBlock: React.FC<ISpinnerBlockProps> = (props) => {
  const { message, className } = props;

  return (
    <Delay
      ms={250}
      after={
        <div
          className={classNames('w-full h-full flex flex-col items-center justify-center', className)}
          style={{
            minHeight: '24rem',
          }}
        >
          <Spinner className="mr-2" size={12} />
          <p className="my-4 text-base text-gray-600 font-medium">{message}</p>
        </div>
      }
    />
  );
};

import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { useAuth } from '../../../contexts/auth-context';
import { getDisplayError } from '../../../utils/get-display-error';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type {
  BodyType as CreateTeamPayload,
  ResponseType as CreateTeamResponseType,
} from '../endpoints/CreateTeamEndpoint';
import { LanguageEnum } from '../../workspaceDocument/enums';
import { captureException } from '@sentry/react';

const createTeamSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
});

export const CreateTeamPage = () => {
  const navigate = useNavigate();
  const { refreshMe } = useAuth();

  return (
    <div className="page-content">
      <PageHeader title="Create a team" />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Teams',
              to: '/app/t',
            },
            {
              name: 'Create a team',
            },
          ]}
        />
      </div>

      <div className="card">
        <Formik
          initialValues={{
            name: '',
            language: LanguageEnum.EN,
          }}
          validationSchema={createTeamSchema}
          onSubmit={async (values) => {
            try {
              const payload: CreateTeamPayload = {
                name: values.name,
                language: values.language,
              };
              const result = await fetchEndpointData<CreateTeamResponseType>('/api/v1/team/create', {
                method: 'POST',
                body: payload,
              });
              refreshMe();
              navigate(`/app/t/${result.team.id}`);
              toast.success('Team created');
            } catch (err: any) {
              captureException(err);
              toast.error('Could not create team: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <LanguageSelectField labelText="Language" name="language" />

              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Create Team
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

import React from 'react';

export interface InputWrapperProps {
  labelText: string;
  invalidText?: string;
  helperText?: string;
  children: React.ReactElement;
  noLabel?: boolean;
}

const Label: React.FC<{ children: React.ReactNode; noLabel?: boolean }> = (props) => {
  return React.createElement(props.noLabel ? 'div' : 'label', { className: 'block w-full text-left' }, props.children);
};

export const InputWrapper: React.FC<InputWrapperProps> = (props) => {
  const { labelText, invalidText, helperText, children, noLabel } = props;

  return (
    <div className="w-full">
      <Label noLabel={noLabel}>
        <div className="label-text py-1 w-full mb-1">{labelText}</div>
        <div>{children}</div>
      </Label>

      <div className="text-sm mt-1 mb-2 w-full">
        {invalidText ? (
          <span className="text-danger-color dark:hover:text-danger-color">{invalidText}</span>
        ) : (
          <span className="text-gray-600">{helperText ?? <>&zwnj;</>}</span>
        )}
      </div>
    </div>
  );
};

import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { Link, LinkProps } from 'react-router-dom';

import classNames from '@/utils/classnames';
import { Spinner } from '../Spinner';

const buttonVariants = cva(
  'flex items-center justify-center rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-dark-07 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none select-none transition-colors duration-200',
  {
    variants: {
      variant: {
        default: 'hover:bg-secondary-700 bg-secondary-800 text-white',
        primary: 'bg-primary-800 text-white hover:bg-primary-700',
        destructive: 'bg-danger-color text-white hover:bg-danger-color-dark',
        outline: 'bg-transparent border border-slate-200 hover:bg-slate-100',
        ghost: 'bg-transparent hover:bg-gray-300 data-[state=open]:bg-transparent',
        link: 'bg-transparent underline-offset-4 hover:underline text-white hover:bg-transparent',
      },
      size: {
        6: 'h-6',
        8: 'h-8',
        10: 'h-10',
        12: 'h-12',
      },
      shape: {
        square: '',
        rectangle: '',
      },
    },
    compoundVariants: [
      {
        shape: 'square',
        size: 6,
        className: 'w-6',
      },
      {
        shape: 'square',
        size: 10,
        className: 'w-10',
      },
      {
        shape: 'square',
        size: 12,
        className: 'w-12',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 10,
      shape: 'rectangle',
    },
  },
);

export interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
}

const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ className, variant, size, shape, isLoading, iconLeft, iconRight, children, ...otherProps }, passedRef) => {
    const normalizedSize = size || 12;

    return (
      <button
        className={classNames(buttonVariants({ variant, size, shape, className }), 'relative overflow-hidden')}
        {...otherProps}
        ref={passedRef}
      >
        <div className="relative h-full">
          <div className="w-full flex h-full items-center justify-center whitespace-nowrap overflow-hidden">
            {iconLeft != null && <div className="py-4 pl-4">{iconLeft}</div>}
            <div
              className={classNames({
                'px-4': normalizedSize >= 10,
                'px-2': normalizedSize < 10,
              })}
            >
              {children}
            </div>
            {iconRight != null && <div className="py-4 pr-4">{iconRight}</div>}
          </div>
          {isLoading && (
            <div
              className={classNames('absolute flex items-center justify-center top-0 left-0 w-full h-full')}
              style={{ background: `rgba(0, 0, 0, 0.25)` }}
            >
              <Spinner size={4} className="mr-2" />
            </div>
          )}
        </div>
      </button>
    );
  },
);
BaseButton.displayName = 'BaseButton';

export interface BaseLinkButtonProps extends LinkProps, VariantProps<typeof buttonVariants> {
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isExternal?: boolean;
}

const BaseLinkButton: React.FC<BaseLinkButtonProps> = (props) => {
  const { className, variant, size, iconLeft, iconRight, children, isExternal, to, ...otherProps } = props;

  return React.createElement(
    isExternal ? 'a' : Link,
    {
      ...otherProps,
      // @ts-ignore
      to: isExternal ? undefined : to,
      href: isExternal ? to : undefined,
      className: classNames(buttonVariants({ variant, size, className })),
    },
    <div className="w-full flex whitespace-nowrap">
      {iconLeft != null && <div className="py-4 pl-4 flex items-center justify-center flex-shrink-0">{iconLeft}</div>}
      <div className="px-4 flex items-center">{children}</div>
      {iconRight != null && <div className="py-4 pr-4 flex items-center justify-center flex-shrink-0">{iconRight}</div>}
    </div>,
  );
};
BaseLinkButton.displayName = 'BaseLinkButton';

export { BaseButton, BaseLinkButton, buttonVariants };

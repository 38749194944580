import React, { useMemo } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';

import createContext from '../../contexts/create-context';

interface IDialogProviderValue {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  showTrigger: boolean;
}

const [useContext, ReactProvider] = createContext<IDialogProviderValue>();

export interface IDialogRootProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  showTrigger?: boolean;
  children?: React.ReactNode;
}

const NOOP = () => {};

export const DialogRoot: React.FC<IDialogRootProps> = (props) => {
  const { open, onOpenChange, showTrigger, children } = props;

  const value = useMemo<IDialogProviderValue>(() => {
    return {
      isOpen: open ?? false,
      setOpen: onOpenChange ?? NOOP,
      showTrigger: showTrigger ?? true,
    };
  }, [open, onOpenChange, showTrigger]);

  return <ReactProvider value={value}>{children}</ReactProvider>;
};

export interface IDialogTriggerProps {
  children?: React.ReactNode;
  className?: string;
}

export const DialogTrigger: React.FC<IDialogTriggerProps> = (props) => {
  const { children } = props;
  const { showTrigger } = useContext();

  if (!showTrigger) {
    return;
  }

  return <div>{children}</div>;
};

export interface IDialogContentProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
}

export const DialogContent: React.FC<IDialogContentProps> = (props) => {
  const { children, className, style, title } = props;
  const { isOpen, setOpen } = useContext();

  if (!isOpen) {
    return;
  }

  return (
    <RadixDialog.Root open={isOpen} onOpenChange={setOpen}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay
          className="dialog-overlay"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        />

        <RadixDialog.Content
          className={className}
          style={style}
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <RadixDialog.Title className="visually-hidden">{title ?? 'dialog'}</RadixDialog.Title>

          {children}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import type {
  BodyType as UpdateTeamPermissionsPayload,
  ResponseType as UpdateTeamPermissionsResponseType,
} from '../endpoints/UpdateTeamUserEndpoint';
import type { ResponseType as GetUserResponseType } from '../endpoints/TeamUserEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { TeamUserPermissionsSelector } from '../components/UserPermissionsSelector';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { nullthrows } from '@/utils/invariant';
import { TeamPermissionEnum } from '@/app/auth/enum';
import { captureException } from '@sentry/react';

const updateTeamUserSchema = Yup.object().shape({
  permissions: Yup.array(Yup.string()).required('Vereist'),
  isOwner: Yup.boolean().required('Vereist'),
});

export const UpdateTeamUserPage = () => {
  const navigate = useNavigate();
  const { team } = useTeam();
  const { userId: _userId } = useParams<{ userId: string }>();
  const userId = nullthrows(_userId, 'userId is required');

  const usersUrl = useResolvedPath('..');

  const { data, isLoading, mutate } = useSWR<GetUserResponseType>(
    `/api/v1/team/user/get?teamId=${team.id}&userId=${userId}`,
    fetchEndpointData,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const user = nullthrows(data?.user, 'User not found');
  return (
    <div className="page-content">
      <PageHeader title={`Update user ${user.name}`} />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: user.name,
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              permissions: user.isOwner
                ? [...Object.values(TeamPermissionEnum)]
                : (user.permissions as TeamPermissionEnum[]),
              isOwner: user.isOwner,
            }}
            validationSchema={updateTeamUserSchema}
            onSubmit={async (values) => {
              try {
                const payload: UpdateTeamPermissionsPayload = {
                  teamId: team.id,
                  userId,
                  newPermissions: values.permissions,
                  isOwner: values.isOwner,
                };
                await fetchEndpointData<UpdateTeamPermissionsResponseType>('/api/v1/team/user/update', {
                  method: 'POST',
                  body: payload,
                });
                mutate();
                navigate('..');
                toast.success('User has been updated');
              } catch (err: any) {
                captureException(err);
                toast.error('Could not update team user: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="heading-two mb-2">Permissions</div>

                  <div className="mb-4">
                    <TeamUserPermissionsSelector
                      value={values['permissions']}
                      onChange={(v) => setFieldValue('permissions', v)}
                    />
                  </div>

                  <CheckboxField labelText="Owner" name="isOwner" />

                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                    Update Permissions
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
